import { Button, FormInstance, AutoComplete } from 'antd';
import locale from 'antd/es/date-picker/locale/pl_PL';

import { StyledForm } from './styles';

import { StyledFormItem, StyledModal } from '@/components/Styled';
import { modules } from '@/config';
import { LotsFormType, LotOptionType } from '@/types/lots';

import { DatePicker } from '../../Pickers';

interface Props {
  form: FormInstance<LotsFormType>;
  onCancel: ()=> void;
  onFinish: ()=> void;
  getOptions: (name: string)=> void;
  removeChars: (name: string, value: string)=> void;
  open: boolean;
  isEdit: boolean;
  isLoading: boolean;
  options: LotOptionType[];
  focusedField: string;
}

const filterOption = (
  inputValue: string,
  option?: LotOptionType
): boolean => option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;

const ManageLot = ({ form, onCancel, onFinish, getOptions, removeChars, open, isEdit, isLoading, options, focusedField }: Props): JSX.Element => (
  <StyledModal
    footer={[
      <Button
        key="cancel"
        onClick={onCancel}
      >
        Anuluj
      </Button>,
      <Button
        key="submit"
        loading={isLoading}
        onClick={form.submit}
        type="primary"
      >
        { isEdit ? 'Zapisz' : 'Dodaj' }
      </Button>
    ]}
    maskClosable={false}
    onCancel={onCancel}
    onOk={form.submit}
    open={open}
    title={isEdit ? 'Edytuj etykietę' : 'Dodaj etykietę'}
    width={680}
  >
    <StyledForm
      form={form}
      layout="vertical"
      onFinish={onFinish}
      requiredMark={false}
      validateTrigger="onBlur"
    >
      <StyledFormItem
        label="Data początkowa"
        name="startDate"
        rules={[{ required: true, message: 'Data początkowa jest wymagana' }]}
      >
        <DatePicker
          disabled={isLoading}
          format="DD.MM.YYYY HH:mm"
          locale={locale}
          showTime
          style={{ width: '100%' }}
        />
      </StyledFormItem>
      <StyledFormItem
        label="Nazwa"
        name="name"
        rules={[{ required: true, message: 'Nazwa jest wymagana' }]}
      >
        <AutoComplete
          disabled={isLoading}
          filterOption={filterOption}
          onFocus={(e): void => getOptions(e.target.id)}
          options={focusedField === 'name' ? options : []}
        />
      </StyledFormItem>
      <StyledFormItem
        label="GRM"
        name="grm"
      >
        <AutoComplete
          disabled={isLoading}
          filterOption={filterOption}
          onFocus={(e): void => getOptions(e.target.id)}
          options={focusedField === 'grm' ? options : []}
        />
      </StyledFormItem>
      <StyledFormItem
        label="Waga GRM"
        name="weigthgrm"
      >
        <AutoComplete
          disabled={isLoading}
          filterOption={filterOption}
          onBlur={(e): void => removeChars(e.target.id, (e.target as HTMLInputElement).value)}
          onFocus={(e): void => getOptions(e.target.id)}
          options={focusedField === 'weigthgrm' ? options : []}
        />
      </StyledFormItem>
      <StyledFormItem
        label="LOT"
        name="LOT"
      >
        <AutoComplete
          disabled={isLoading}
          filterOption={filterOption}
          onFocus={(e): void => getOptions(e.target.id)}
          options={focusedField === 'LOT' ? options : []}
        />
      </StyledFormItem>
      <StyledFormItem
        label="Station"
        name="station"
      >
        <AutoComplete
          disabled={isLoading}
          filterOption={filterOption}
          onFocus={(): void => getOptions('station')}
          options={focusedField === 'station' ? options : []}
        />
      </StyledFormItem>
      { modules.batch
      && (
        <StyledFormItem
          label="Batch"
          name="prodbatch"
        >
          <AutoComplete
            disabled={isLoading}
            filterOption={filterOption}
            onBlur={(e): void => removeChars(e.target.id, (e.target as HTMLInputElement).value)}
            onFocus={(e): void => getOptions(e.target.id)}
            options={focusedField === 'prodbatch' ? options : []}
          />
        </StyledFormItem>
      ) }
      { modules.extnumord
      && (
        <StyledFormItem
          label="Extnumord"
          name="extnumord"
        >
          <AutoComplete
            disabled={isLoading}
            filterOption={filterOption}
            onBlur={(e): void => removeChars(e.target.id, (e.target as HTMLInputElement).value)}
            onFocus={(e): void => getOptions(e.target.id)}
            options={focusedField === 'extnumord' ? options : []}
          />
        </StyledFormItem>
      ) }
      { modules.numpalet
      && (
        <StyledFormItem
          label="Nr palety"
          name="numpalet"
        >
          <AutoComplete
            disabled={isLoading}
            filterOption={filterOption}
            onBlur={(e): void => removeChars(e.target.id, (e.target as HTMLInputElement).value)}
            onFocus={(e): void => getOptions(e.target.id)}
            options={focusedField === 'numpalet' ? options : []}
          />
        </StyledFormItem>
      ) }
    </StyledForm>
  </StyledModal>
);

export default ManageLot;
