import { RedoOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import styled from 'styled-components';

export const StyledAlert = styled(Alert)`
  margin-bottom: 24px;

  .ant-alert-message {
    margin-bottom: 8px;
  }

  .anticon {
    margin-top: 2px;
  }
`;

export const Paragraph = styled.p`
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

export const Card = styled.div`
  background-color: #FFFFFF;
  border: 1px solid #F0F0F0;
`;

export const Title = styled.div`
  font-weight: 400;
  color: #222222;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  padding: 24px;
`;

export const StyledIcon = styled(RedoOutlined)`
  cursor: pointer;

  svg {
    fill: #1890FF;
  }
`;

export const SliderContainer = styled.div`
  padding: 24px 24px 24px 18px;
  border-top: 1px solid #F0F0F0;
  background-color: #FAFAFA;
`;

export const SliderLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 6px;
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 20px;
`;

export const SliderGroup = styled.div`
  display: flex;
  gap: 24px;
`;
