import axios from '../http-common';

import { ThresholdsDataType, ThresholdsValuesType } from '@/types/thresholds';

export const getThresholds = async (): Promise<ThresholdsDataType> => {
  try {
    const { data } = await axios.get<ThresholdsDataType>('/thresholds');
    return data;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const updateThresholds = async (data: ThresholdsValuesType): Promise<void> => {
  try {
    await axios.put('/thresholds', data);
  } catch (error) {
    throw new Error((error as Error).message);
  }
};
