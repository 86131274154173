import { PickerProps, PickerTimeProps } from 'antd/es/date-picker/generatePicker';
import { Dayjs } from 'dayjs';
import { Component, forwardRef } from 'react';

import DatePicker from './DatePicker';

export type TimePickerProps = Omit<PickerTimeProps<Dayjs>, 'picker'>;

const TimePicker = forwardRef<Component<PickerProps<Dayjs>>, TimePickerProps>((props, ref) => (
  <DatePicker
    {...props}
    ref={ref}
    mode={undefined}
    picker="time"
  />
));

TimePicker.displayName = 'TimePicker';

export default TimePicker;
