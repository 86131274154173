import { ResponsiveBar } from '@nivo/bar';
import { useWindowWidth } from '@react-hook/window-size';
import dayjs from 'dayjs';

import CustomTooltip, { legend } from '../CustomTooltip';

import { ChartType } from '@/types/sample';

interface Props {
  chartData: ChartType;
  chartKey: string;
  chartType: string;
  chartValues: string;
  chartTitle?: string;
  selectedTime?: string;
}

const keys = [
  'no_error',
  'gaping',
  'overgrowth',
  'melanin',
  'hemat',
  'multiple'
];

const keysPercentage = [
  'no_error_percentage',
  'gaping_percentage',
  'overgrowth_percentage',
  'melanin_percentage',
  'hemat_percentage',
  'multiple_percentage'
];

const legendValues = [
  {
    id: 'no_error',
    label: 'Brak wad',
    color: '#7fc97f'
  },
  {
    id: 'gaping',
    label: 'Gaping',
    color: '#fdb562'
  },
  {
    id: 'overgrowth',
    label: 'Przerost',
    color: '#ffd92f'
  },
  {
    id: 'melanin',
    label: 'Melanina',
    color: '#80b0d3'
  },
  {
    id: 'hemat',
    label: 'Krwiak',
    color: '#fb8072'
  },
  {
    id: 'multiple',
    label: 'Wiele wad',
    color: '#d9d9d9'
  }
];

const Bar = ({ chartData, chartKey, chartType, chartValues, chartTitle, selectedTime }: Props): JSX.Element => {
  const width = useWindowWidth();

  return (
    <ResponsiveBar
      axisBottom={{
        tickSize: 0,
        tickPadding: 10,
        tickRotation: 0,
        legend: chartTitle,
        legendPosition: 'middle',
        legendOffset: 50,
        format: chartKey === 'ts' ? (value): string => (selectedTime === '1d' ? dayjs(value).format('DD.MM.YYYY') : dayjs(value).format('HH:mm')) : undefined
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 10,
        legend: chartValues === 'percent' ? 'Procenty' : 'Sztuki',
        legendPosition: 'middle',
        legendOffset: -55,
        format: chartValues === 'percent' ? (value): string => `${value}%` : undefined
      }}
      colors={({ id }): string => legend(String(id), legendValues).color}
      data={chartType === 'stacked' ? chartData.stacked : chartData.grouped}
      groupMode={chartType === 'grouped' ? 'grouped' : 'stacked'}
      indexBy={chartKey}
      indexScale={{ type: 'band', round: true }}
      keys={chartValues === 'percent' ? keysPercentage : keys}
      labelSkipHeight={12}
      labelSkipWidth={12}
      labelTextColor={{
        from: 'color',
        modifiers: [
          [
            'darker',
            1.6
          ]
        ]
      }}
      legendLabel={({ id }): string => legend(String(id), legendValues).label}
      legends={[
        {
          dataFrom: 'keys',
          anchor: width > 576 ? 'bottom-right' : 'bottom-left',
          direction: 'column',
          justify: false,
          translateX: width > 576 ? 110 : 0,
          translateY: width > 576 ? 0 : 190,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: 'left-to-right',
          itemOpacity: 0.85,
          symbolSize: 20,
          toggleSerie: true,
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
      margin={{ top: 30, right: width > 576 ? 110 : 50, bottom: width > 576 ? 50 : 220, left: 70 }}
      padding={0.3}
      role="application"
      tooltip={({ id, indexValue, formattedValue }): JSX.Element => CustomTooltip(
        String(id),
        formattedValue,
        chartKey === 'ts' ? dayjs(Number(indexValue)).format('DD.MM.YYYY HH:mm') : indexValue,
        legendValues
      )}
      valueFormat={ chartValues === 'percent' ? (value: number): string => `${value.toFixed(2)}%` : undefined}
      valueScale={{ type: 'linear', max: (chartValues === 'percent' && chartType === 'stacked') ? 100 : 'auto' }}
    />
  );
};

export default Bar;
