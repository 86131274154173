import { ConfigProvider } from 'antd';
import plPL from 'antd/es/locale/pl_PL';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import 'dayjs/locale/pl';

import CurrentData from './components/CurrentData';
import Login from './components/Login';
import Lots from './components/Lots';
import NavMenu from './components/NavMenu';
import Reports from './components/Reports';
import SampleAnalysis from './components/SampleAnalysis';
import SystemStatus from './components/SystemStatus';
import Thresholds from './components/Thresholds';
import Users from './components/Users';
import { interceptorResponse } from './http-common';
import { getUserData } from './services/auth';
import { setSamplesData, setSelected } from './store/features/samplesState';
import { setDefaultValues, setUser } from './store/features/user';
import { useAppDispatch, useAppSelector } from './store/hooks';

dayjs.locale('pl');
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Europe/Warsaw');

const App = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const { samplesData, isSelected } = useAppSelector(({ samplesState }) => samplesState);
  const { user, menuOptions } = useAppSelector(({ userState }) => userState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    interceptorResponse((): void => {
      dispatch(setDefaultValues());
      navigate('/login');
    });

    const getUser = async (): Promise<void> => {
      try {
        dispatch(setUser(await getUserData()));
      } catch (error) {
        console.error((error as Error).message);
      }
    };
    getUser();
  }, []);

  useEffect(() => {
    if (samplesData.length) {
      dispatch(setSamplesData([]));
    }
    if (isSelected) {
      dispatch(setSelected(false));
    }
  }, [location.pathname]);

  return (
    <ConfigProvider locale={plPL}>
      <Routes>
        { user.permissions.lots && (
          <Route
            element={(
              <>
                <NavMenu />
                <Lots />
              </>
            )}
            path="/lots"
          />
        ) }
        { user.permissions.samples && (
          <Route
            element={(
              <>
                <NavMenu />
                <SampleAnalysis />
              </>
            )}
            path="/analysis"
          />
        ) }
        { user.permissions.todays && (
          <Route
            element={(
              <>
                <NavMenu />
                <CurrentData />
              </>
            )}
            path="/current"
          />
        ) }
        { user.permissions.reports && (
          <Route
            element={(
              <>
                <NavMenu />
                <Reports />
              </>
            )}
            path="/reports"
          />
        ) }
        { user.permissions.systems && (
          <Route
            element={(
              <>
                <NavMenu />
                <SystemStatus />
              </>
            )}
            path="/status"
          />
        ) }
        { user.permissions.users && (
          <Route
            element={(
              <>
                <NavMenu />
                <Users />
              </>
            )}
            path="/users"
          />
        ) }
        { user.permissions.thresholds && (
          <Route
            element={(
              <>
                <NavMenu />
                <Thresholds />
              </>
            )}
            path="/thresholds"
          />
        ) }
        <Route
          element={<Login />}
          path="/login"
        />
        { menuOptions.length && (
          <Route
            element={(
              <Navigate
                replace
                to={`/${menuOptions.find((route) => route)}`}
              />
            )}
            path="*"
          />
        ) }
      </Routes>
    </ConfigProvider>
  );
};

export default App;
