import { CloseOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { NoticeType } from 'antd/lib/message';

import './styles.css';

const customMessage = (description: string, type: NoticeType = 'info', duration = 1): void => {
  const key = Math.random();
  message.open({
    key,
    type,
    content:
  <div className="custom-content">
    <div>{ description }</div>
    <CloseOutlined onClick={(): void => message.destroy(key)} />
  </div>,
    className: 'custom-message',
    duration
  });
};

export default customMessage;
