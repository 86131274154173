import { Descriptions } from 'antd';
import styled from 'styled-components';

export const CustomDescription = styled(Descriptions)`
  margin-bottom: -16px;

  .ant-descriptions-item-content {
    font-weight: 500;
  }
`;

export const DescriptionContainer = styled.div`
  padding: 24px 24px 24px 24px;
  border-bottom: 1px solid #F0F0F0;
`;

export const SelectorRow = styled.div`
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
