import { useWindowHeight } from '@react-hook/window-size';
import { Radio } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';

import { RadioContainer } from '../DefectImages/styles';

import Line from '@/components/Nivo/Line';
import { Container, Label, TabContainer } from '@/components/Styled';
import { getSamplesSummaryData } from '@/services/sample';
import { useAppSelector } from '@/store/hooks';
import { LineData } from '@/types/sample';

interface Props {
  chartHeight?: number;
}

const CurrentSummary = ({ chartHeight }: Props): JSX.Element => {
  const { date } = useAppSelector(({ samplesState }) => samplesState);
  const [data, setData] = useState<LineData[]>();
  const [selectedDate, setSelectedDate] = useState<number>();

  const height = useWindowHeight();

  const options = useMemo(() => {
    const dayCount = dayjs(date.end).diff(dayjs(date.start), 'days');
    const tempArr = [];
    for (let i = 0; i <= dayCount; i += 1) {
      const tempDate = dayjs(date.start).add(i, 'days');
      if (tempDate.day() !== 0) {
        tempArr.push({
          label: tempDate.format('DD.MM.YYYY'),
          value: tempDate.unix() * 1000
        });
      }
    }
    setSelectedDate(tempArr[0].value);
    return tempArr;
  }, [date]);

  const unixDate = useMemo(() => ({
    start: dayjs(selectedDate).unix() * 1000,
    end: dayjs(selectedDate).hour(23)
      .minute(55)
      .unix() * 1000
  }), [selectedDate]);

  useEffect(() => {
    fetchChartData();
  }, [selectedDate]);

  const fetchChartData = async (): Promise<void> => {
    try {
      setData(await getSamplesSummaryData(unixDate.start, unixDate.end, 10));
    } catch (error) {
      console.error((error as Error).message);
    }
  };

  return (
    <TabContainer>
      <RadioContainer>
        <Label>
          Wybrana data
        </Label>
        <Radio.Group
          onChange={({ target }): void => setSelectedDate(target.value)}
          options={options}
          value={selectedDate}
        />
      </RadioContainer>
      <Container style={{ height: chartHeight || height - 264, minHeight: 460 }}>
        { data && (
          <Line
            chartData={data}
            selectedTime="day"
          />
        ) }
      </Container>
    </TabContainer>
  );
};

export default CurrentSummary;
