import { Button } from 'antd';
import styled from 'styled-components';

export const BarContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px 40px 20px 40px;
  background-color: #FFFFFF;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.5);
  z-index: 2;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
    padding: 16px 24px 16px 24px;
  }
`;

export const Label = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 10px;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`;

export const Placeholder = styled.div`
  font-size: 16px;
  line-height: 40px;
  font-weight: 400;
  color: rgba(0,0,0,0.45);
`;

export const StyledButtonGroup = styled(Button.Group)`
  margin-right: 24px;
`;
