import { StyledModal } from './styles';

import ImageCard from '@/components/SampleAnalysis/Tabs/DefectImages/ImageCard';
import { Container } from '@/components/SampleAnalysis/Tabs/DefectImages/styles';
import CONFIG from '@/config';

interface SelectedImageType {
  id: number;
  src: string;
  state: boolean;
  defect: string;
}

interface Props {
  title: string;
  open: boolean;
  defectModalImages: SelectedImageType[];
  onOk: ()=> void;
  onCancel: ()=> void;
  openPreviewModal: (id: number, src: string, state: boolean)=> void;
  toggleImage: (value: number)=> void;
}

const DefectPreviewModal = ({
  title,
  open,
  defectModalImages,
  onOk,
  onCancel,
  openPreviewModal,
  toggleImage
}: Props): JSX.Element => (
  <StyledModal
    centered
    okText="Zatwierdź"
    onCancel={onCancel}
    onOk={onOk}
    open={open}
    title={`Wybrane zdjęcia: ${title}`}
    width="auto"
  >
    <Container>
      {
        defectModalImages.map(({ id, src, state }) => (
          <ImageCard
            key={id}
            isSelected={state}
            openPreviewModal={(): void => openPreviewModal(id, `${CONFIG.bucketLink}${src}`, state)}
            src={`${CONFIG.bucketLink}${src}`}
            toggleImage={(): void => toggleImage(id)}
          />
        ))
      }
    </Container>
  </StyledModal>
);

export default DefectPreviewModal;
