import { DoubleLeftOutlined, DoubleRightOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { PaginationContainer, PaginationContent, Dates, StyledButton, TabContainer } from './styles';

import customMessage from '@/components/Message';
import ImagePreviewModal from '@/components/Modals/ImagePreview';
import ImageCard from '@/components/SampleAnalysis/Tabs/DefectImages/ImageCard';
import { Label, EmptyLabel, Container } from '@/components/SampleAnalysis/Tabs/DefectImages/styles';
import CONFIG from '@/config';
import { getCurrentImages, getCurrentImagesTotalCount } from '@/services/current-data';
import { getAllSampleImages, getSampleImagesTotalCount } from '@/services/sample';
import { useAppSelector } from '@/store/hooks';
import { CurrentImagesTabType } from '@/types/current-data';

interface Props {
  currentData?: boolean;
}

type PaginationButtonType = 'newest' | 'newer' | 'older' | 'oldest';

interface TokensType extends Omit<CurrentImagesTabType, 'images'> {
  currentToken?: number | null;
}

const getToken = (localToken: Omit<CurrentImagesTabType, 'totalCount' | 'images'>, type?: PaginationButtonType): (number | null) => {
  switch (type) {
    case 'newest': {
      return null;
    }
    case 'newer': {
      return localToken.prevToken;
    }
    case 'older': {
      return localToken.nextToken;
    }
    case 'oldest': {
      return localToken.lastToken;
    }
    default: {
      return null;
    }
  }
};

const CurrentImages = ({ currentData }: Props): JSX.Element => {
  const { selectedFields, date } = useAppSelector(({ samplesState }) => samplesState);
  const [data, setData] = useState<CurrentImagesTabType['images']>([]);
  const [newestSelected, setNewestSelected] = useState(true);
  const [newCount, setNewCount] = useState({
    current: 0,
    total: 0
  });

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [previewModalImage, setPreviewModalImage] = useState({ id: 0, src: '' });

  const tokens = useRef<TokensType>({
    totalCount: 0,
    prevToken: 0,
    nextToken: 0,
    lastToken: 0
  });

  const debouncedDownload = useDebouncedCallback((type?: PaginationButtonType) => {
    downloadImages(type);
  }, 300);

  const pagination = useMemo(() => (
    <PaginationContainer>
      <div>
        <Label>
          Wyświetlony zakres
        </Label>
        <Dates>{ !!data.length && `${dayjs(data[0].id).format('dddd, DD.MM.YYYY: HH:mm:ss')} - ${dayjs(data[data.length - 1].id).format('HH:mm:ss')}` }</Dates>
      </div>
      <div>
        <Label>
          Wyświetl
        </Label>
        <PaginationContent>
          <Badge count={newestSelected ? 0 : newCount.total - newCount.current}>
            <StyledButton
              onClick={(): void => {
                if (tokens.current.prevToken) {
                  tokens.current.currentToken = getToken(tokens.current, 'newest');
                  debouncedDownload('newest');
                } else {
                  customMessage('Wyświetlono najnowsze zdjęcia', 'warning', 3);
                }
              }}
              onFocus={(e): React.FocusEvent<Element> => e.target.blur()}
              style={newestSelected ? { borderColor: '#1890FF', color: '#1890FF' } : undefined}
            >
              <span>Najnowsze</span>
              <div><DoubleLeftOutlined /></div>
            </StyledButton>
          </Badge>
          <StyledButton
            onClick={(): void => {
              if (tokens.current.prevToken) {
                tokens.current.currentToken = getToken(tokens.current, 'newer');
                fetchPaginationData('newer');
                debouncedDownload('newer');
              } else {
                customMessage('Wyświetlono najnowsze zdjęcia', 'warning', 3);
              }
            }}
            onFocus={(e): React.FocusEvent<Element> => e.target.blur()}
          >
            <span>Nowsze</span>
            <div><LeftOutlined /></div>
          </StyledButton>
          <StyledButton
            onClick={(): void => {
              if (tokens.current.nextToken) {
                tokens.current.currentToken = getToken(tokens.current, 'older');
                fetchPaginationData('older');
                debouncedDownload('older');
              } else {
                customMessage('Wyświetlono najstarsze zdjęcia', 'warning', 3);
              }
            }}
            onFocus={(e): React.FocusEvent<Element> => e.target.blur()}
          >
            <span>Starsze</span>
            <div><RightOutlined /></div>
          </StyledButton>
          <StyledButton
            onClick={(): void => {
              if (tokens.current.nextToken) {
                tokens.current.currentToken = getToken(tokens.current, 'oldest');
                debouncedDownload('oldest');
              } else {
                customMessage('Wyświetlono najstarsze zdjęcia', 'warning', 3);
              }
            }}
            onFocus={(e): React.FocusEvent<Element> => e.target.blur()}
          >
            <span>Najstarsze</span>
            <div><DoubleRightOutlined /></div>
          </StyledButton>
        </PaginationContent>
      </div>
    </PaginationContainer>
  ), [newCount, newestSelected, tokens.current]);

  useEffect(() => {
    downloadImages();
  }, [selectedFields]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (newestSelected) {
        downloadImages();
      } else {
        getTotalCount();
      }
    }, CONFIG.refreshTime);
    return () => clearInterval(interval);
  }, [newestSelected]);

  useEffect(() => {
    if (tokens.current?.prevToken === null) {
      paginationAction('newest');
    }
  }, [tokens.current]);

  const fetchPaginationData = async (type?: PaginationButtonType): Promise<void> => {
    try {
      const { images, ...rest } = await getCurrentImages(date.start, date.end, getToken(tokens.current, type), true);
      tokens.current = {
        ...tokens.current,
        ...rest
      };
    } catch (error) {
      console.error((error as Error).message);
    }
  };

  const paginationAction = (type?: PaginationButtonType): void => {
    if (type === 'newest') {
      setNewCount({
        current: tokens.current.totalCount,
        total: tokens.current.totalCount
      });
      setNewestSelected(true);
    } else {
      setNewestSelected(false);
    }
  };

  const downloadImages = async (type?: PaginationButtonType): Promise<void> => {
    if (currentData) {
      try {
        const { images, ...rest } = await getCurrentImages(date.start, date.end, tokens.current.currentToken);
        tokens.current = {
          ...tokens.current,
          ...rest
        };
        setData(images);
        paginationAction(type);
        setNewCount((prev) => ({
          current: prev.current || rest.totalCount,
          total: rest.totalCount
        }));
      } catch (error) {
        console.error((error as Error).message);
      }
    } else {
      try {
        const { images, ...rest } = await getAllSampleImages(date.start, date.end, tokens.current.currentToken);
        tokens.current = {
          ...tokens.current,
          ...rest
        };
        setData(images);
        paginationAction(type);
        setNewCount((prev) => ({
          current: prev.current || rest.totalCount,
          total: rest.totalCount
        }));
      } catch (error) {
        console.error((error as Error).message);
      }
    }
  };

  const getTotalCount = async (): Promise<void> => {
    if (currentData) {
      try {
        const totalCount = await getCurrentImagesTotalCount(date.start, date.end);
        setNewCount((prev) => ({
          current: prev.current || totalCount,
          total: totalCount
        }));
      } catch (error) {
        console.error((error as Error).message);
      }
    } else {
      try {
        const totalCount = await getSampleImagesTotalCount(date.start, date.end);
        setNewCount((prev) => ({
          current: prev.current || totalCount,
          total: totalCount
        }));
      } catch (error) {
        console.error((error as Error).message);
      }
    }
  };

  const openPreviewModal = (id: number, src: string): void => {
    setPreviewModalImage({ id, src });
    setIsPreviewModalOpen(true);
  };

  const switchImage = (direction: string): void => {
    const tempIndex = data.findIndex(({ id }) => id === previewModalImage.id);

    if (direction === 'ArrowLeft') {
      if (tempIndex) {
        setPreviewModalImage({ id: data[tempIndex - 1].id, src: `${CONFIG.bucketLink}${data[tempIndex - 1].src}` });
      } else {
        customMessage('Wyświetlono pierwsze zdjęcie', 'warning', 3);
      }
    }
    if (direction === 'ArrowRight') {
      if (tempIndex !== data.length - 1) {
        setPreviewModalImage({ id: data[tempIndex + 1].id, src: `${CONFIG.bucketLink}${data[tempIndex + 1].src}` });
      } else {
        customMessage('Wyświetlono ostatnie zdjęcie', 'warning', 3);
      }
    }
  };

  return (
    <TabContainer>
      { pagination }
      <Container>
        {
          data.length
            ? data.map(({ id, src }) => (
              <ImageCard
                key={id}
                notSelectable
                openPreviewModal={(): void => openPreviewModal(id, `${CONFIG.bucketLink}${src}`)}
                src={`${CONFIG.bucketLink}${src}`}
              />
            ))
            : (
              <EmptyLabel>
                Brak zdjęć do wyświetlenia
              </EmptyLabel>
            )
        }
      </Container>
      <ImagePreviewModal
        notSelectable
        onCancel={(): void => setIsPreviewModalOpen(false)}
        open={isPreviewModalOpen}
        src={previewModalImage.src}
        switchImage={switchImage}
      />
    </TabContainer>
  );
};

export default CurrentImages;
