import { CheckCircleFilled } from '@ant-design/icons';
import { Button } from 'antd';
import styled from 'styled-components';

interface StyledCardType {
  selected?: boolean;
}

export const Card = styled.div<StyledCardType>`
  border-radius: 10px;
  position: relative;
  padding: 4px;
  min-height: 206px;

  @media (max-width: 1400px) {
    min-height: 196px;
  }

  ${({ selected }): string => (selected
    ? 'background-color: #1890FF;'
    : '')
}
`;

export const Image = styled.img`
  aspect-ratio: 16 / 10;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
`;

export const StyledIcon = styled(CheckCircleFilled)<StyledCardType>`
  display: none;
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #FFFFFF;
  border-radius: 50%;

  svg {
    height: 32px;
    width: 32px;
    fill: #1890FF;
  }

  ${({ selected }): string => (selected
    ? 'display: block;'
    : '')
}
`;

export const ButtonContainer = styled.div`
  @keyframes showContainer {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  display: none;

  ${Card}:hover & {
    display: block;
    animation: showContainer 250ms ease-in-out both;
  }
`;

export const ButtonContent = styled.div`
  position: absolute;
  bottom: 4px;
  left: 4px;
  width: calc(100% - 8px);
  display: flex;
  align-items: center;
  padding: 8px 0 12px 0;
  background-color: rgba(0,0,0,0.8);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const ButtonColumn = styled.div`
  width: 50%;
  text-align: center;
  position: relative;
  margin: 0 auto;

  :first-of-type:not(:last-of-type) {
    :after {
      content: '';
      position: absolute;
      width: 1px;
      top: 6px;
      bottom: 4px;
      right: 0;
      background-color: rgba(255,255,255,0.2);
    }
  }
`;

export const StyledButton = styled(Button)`
  color: #FFFFFF;
`;
