import { useWindowSize } from '@react-hook/window-size';
import { Layout, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { Container, ImageCard, StatusRow, StatusDate, StatusGroup, StatusTitle, TableCard, ImageRow, SystemImage, ImagePlaceholder } from './styles';

import { Header, HeaderContainer } from '../Styled';

import { getSystemStatus, getSystemImage } from '@/services/system-status';
import { SystemStatusTableType, SystemImageType } from '@/types/system-status';

const { Content } = Layout;

const columns: ColumnsType<SystemStatusTableType> = [
  {
    title: 'Data',
    dataIndex: 'date',
    key: 'date',
    width: '50%'
  },
  {
    title: 'Tags',
    key: 'tags',
    dataIndex: 'tags',
    width: '50%',
    render: (_, { status }) => (
      status
        ? (
          <Tag
            key={status}
            color="processing"
          >
            Online
          </Tag>
        )
        : (
          <Tag
            key={status}
            color="red"
          >
            Offline
          </Tag>
        )
    )
  }
];

const SystemStatus = (): JSX.Element => {
  const [data, setData] = useState<SystemStatusTableType[]>([]);
  const [imageData, setImageData] = useState<SystemImageType>();
  const [isLoading, setIsLoading] = useState(false);

  const [width, height] = useWindowSize();

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        setIsLoading(true);
        setData(await getSystemStatus());
        setImageData(await getSystemImage());
      } catch (error) {
        console.error((error as Error).message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <Content>
      <HeaderContainer>
        <Header>
          Status systemu
        </Header>
      </HeaderContainer>
      <Container style={{ height: width > 768 ? height - 166 : undefined }}>
        <ImageCard>
          <StatusRow>
            <StatusDate>
              { dayjs(imageData?.date).format('dddd, DD MMMM YYYY - HH:mm') }
            </StatusDate>
            <StatusGroup>
              <StatusTitle>
                Status:
              </StatusTitle>
              {
                data[0]?.status
                  ? (
                    <Tag color="processing">
                      Online
                    </Tag>
                  )
                  : (
                    <Tag color="red">
                      Offline
                    </Tag>
                  )
              }
            </StatusGroup>
          </StatusRow>
          <ImageRow>
            { imageData
              ? <SystemImage src={imageData?.url} />
              : (
                <ImagePlaceholder>
                  Nie udało się wczytać obrazu
                </ImagePlaceholder>
              ) }
          </ImageRow>
        </ImageCard>
        <TableCard>
          <Table
            columns={columns}
            dataSource={data}
            loading={isLoading}
            pagination={false}
            scroll={{ y: height - 258 }}
          />
        </TableCard>
      </Container>
    </Content>
  );
};

export default SystemStatus;
