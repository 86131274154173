import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  margin: 24px 0;

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const StyledButton = styled(Button)`
  @media (max-width: 768px) {
    grid-row-start: 2;
    margin: 0 auto;
    grid-row-end: 3;
  }
`;

export const PreviewImage = styled.img`
  display: block;
  min-width: 600px;
  min-height: 300px;
  max-height: 75vh;
  margin: 0 auto;
  padding: 0 24px;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 24px;
    grid-column: 1 / 3;
    min-width: inherit;
    min-height: inherit;
    padding: 0;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledIcon = styled(CheckCircleFilled)`
  margin-right: 12px;

  svg {
    height: 24px;
    width: 24px;
    fill: #1890FF;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-left: auto;
`;
