import { CheckOutlined, CloseOutlined, FullscreenOutlined } from '@ant-design/icons';

import { Card, Image, StyledIcon, ButtonContainer, ButtonContent, ButtonColumn, StyledButton } from './styles';

interface Props {
  isSelected?: boolean;
  notSelectable?: boolean;
  src: string;
  toggleImage?: ()=> void;
  openPreviewModal: ()=> void;
}

const ImageCard = ({ isSelected, notSelectable, src, toggleImage, openPreviewModal }: Props): JSX.Element => (
  <Card selected={isSelected}>
    <StyledIcon
      onClick={toggleImage}
      selected={isSelected}
    />
    <Image
      loading="lazy"
      src={src}
    />
    <ButtonContainer>
      <ButtonContent>
        { !notSelectable && (
          <ButtonColumn>
            { isSelected
              ? (
                <StyledButton
                  icon={<CloseOutlined />}
                  onClick={toggleImage}
                  size="large"
                  type="link"
                >
                  Odznacz
                </StyledButton>
              )
              : (
                <StyledButton
                  icon={<CheckOutlined />}
                  onClick={toggleImage}
                  size="large"
                  type="link"
                >
                  Zaznacz
                </StyledButton>
              ) }
          </ButtonColumn>
        ) }
        <ButtonColumn>
          <StyledButton
            icon={<FullscreenOutlined />}
            onClick={openPreviewModal}
            size="large"
            type="link"
          >
            Powiększ
          </StyledButton>
        </ButtonColumn>
      </ButtonContent>
    </ButtonContainer>
  </Card>
);

export default ImageCard;
