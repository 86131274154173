import { Form, Input } from 'antd';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Container, LeftColumn, RightColumn, Card, Partners, Logo, ClientLogo, LogoContainer, SystemLogo, Header, StyledFormItem, StyledButton } from './styles';

import { logoBlue, systemLogo, hanseaticLogo, dassonLogo } from '@/assets';
import CONFIG from '@/config';
import { authorize, getUserData } from '@/services/auth';
import { setUser } from '@/store/features/user';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

const Login = (): JSX.Element => {
  const { menuOptions } = useAppSelector(({ userState }) => userState);
  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const navigate = useNavigate();

  useEffect(() => {
    if (menuOptions.length) {
      navigate(`/${menuOptions.find((route) => route)}`);
    }
  }, [menuOptions]);

  const onFinish = async (): Promise<void> => {
    const { email, password } = form.getFieldsValue(true);
    try {
      await authorize(email, password);
      dispatch(setUser(await getUserData()));
    } catch (error) {
      console.error((error as Error).message);
    }
  };

  return (
    <Container>
      <LeftColumn>
        <Partners>
          <Logo src={logoBlue} />
          { CONFIG.clientName === 'dasson' && <ClientLogo src={dassonLogo} /> }
          { CONFIG.clientName === 'hanseatic' && <ClientLogo src={hanseaticLogo} /> }
        </Partners>
        <Card>
          <LogoContainer>
            <SystemLogo src={systemLogo} />
          </LogoContainer>
          <Header>Zaloguj</Header>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            requiredMark={false}
            validateTrigger="onBlur"
          >
            <StyledFormItem
              label="Email"
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'Wprowadzono email o niepoprawnym formacie'
                },
                {
                  required: true,
                  message: 'Email jest wymagany'
                }
              ]}
            >
              <Input />
            </StyledFormItem>
            <StyledFormItem
              label="Hasło"
              name="password"
              rules={[{ required: true, message: 'Hasło jest wymagane' }]}
            >
              <Input.Password />
            </StyledFormItem>
            <StyledFormItem>
              <StyledButton
                htmlType="submit"
                type="primary"
              >
                Zaloguj
              </StyledButton>
            </StyledFormItem>
          </Form>
        </Card>
      </LeftColumn>
      <RightColumn />
    </Container>
  );
};

export default Login;
