import { Container, Title, Body, Highlight } from './styles';

interface LegendValues {
  id: string;
  label: string;
  color: string;
  hidden?: boolean;
}

interface Legend {
  id: string;
  label: string;
  color: string;
}

export const legend = (
  value: string,
  legendValues: LegendValues[]
): Legend => legendValues.find(({ id }) => value.includes(id))
|| {
  id: '',
  label: '',
  color: ''
};

const CustomTooltip = (id: string, value: string, title: string | number, legendValues: LegendValues[]): JSX.Element => (
  <Container color={legend(id, legendValues).color}>
    <Title>
      { title }
    </Title>
    <Body>
      { `${legend(id, legendValues).label}: ` }
      <Highlight>
        { value }
      </Highlight>
    </Body>
  </Container>
);

export default CustomTooltip;
