import { Form, Button } from 'antd';
import styled from 'styled-components';

import { machineImage } from '@/assets';

export const LeftColumn = styled.div`
  height: 100%;
  width: 50%;
`;

export const RightColumn = styled.div`
  height: 100%;
  width: 50%;
  background-image: url(${machineImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Card = styled.div`
  padding: 60px 80px 80px 80px;
  width: 100%;
  max-width: 520px;
  margin: 0 auto;
`;

export const Partners = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px;
`;

export const Logo = styled.img`
  width: 100%;
  max-width: 220px;
  max-height: 54px;
`;

export const ClientLogo = styled.img`
  width: 100%;
  max-width: 120px;
  max-height: 120px;
`;

export const LogoContainer = styled.div`
  border-bottom: 1px solid #D9D9D9;
  padding-bottom: 48px;
  margin-bottom: 32px;
`;

export const SystemLogo = styled.img`
  display: block;
  width: 100%;
  max-width: 320px;
  max-height: 24px;
  margin: 0 auto;
`;

export const Header = styled.h1`
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 24px;
`;

export const StyledFormItem = styled(Form.Item)`
  font-weight: 500;

  &:last-of-type {
    margin-top: 36px;
    margin-bottom: 0;
  }

  .ant-form-item-label {
    padding-bottom: 4px;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  background-color: #FFFFFF;
  position: relative;
  overflow-y: auto;

  @media (max-width: 992px) {
    display: flex;
    flex-direction: column;

    ${LeftColumn} {
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      height: inherit;
    }

    ${RightColumn} {
      display: none;
    }

    ${Partners} {
      padding: 24px;
    }
  }

  @media (max-width: 768px) {
    ${Card} {
      padding: 24px 24px 48px 24px;
    }

    ${Logo} {
      max-width: 180px;
      max-height: 45px;
    }

    ${ClientLogo} {
      max-width: 100px;
      max-height: 84px;
    }
  }
`;
