import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { Layout, Menu, Dropdown } from 'antd';
import styled from 'styled-components';

const { Header } = Layout;

interface StyledMenuType {
  isOpen?: boolean;
}

export const StyledHeader = styled(Header)`
  width: 100%;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  background-color: #0E243E;
  padding: 0 24px;
  height: 60px;
  line-height: 60px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  z-index: 5;

  @media (max-width: 576px) {
    justify-content: space-between;
  }
`;

export const LogoContainer = styled.div`
  min-width: 200px;
`;

export const Logo = styled.img`
  width: 148px;
  height: 36px;
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

export const StyledMenuIcon = styled(MenuOutlined)`
  svg {
    fill: #FFFFFF;
  }
`;

export const StyledCloseIcon = styled(CloseOutlined)`
  svg {
    fill: #FFFFFF;
  }
`;

export const Dimmer = styled.div<StyledMenuType>`
  width: calc(100% - 400px);

  @media (max-width: 576px) {
    ${({ isOpen }): string => (isOpen
    ? `width: inherit;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.1);`
    : '')}
  }
`;

export const MenuContainer = styled.div<StyledMenuType>`
  display: flex;
  justify-content: center;

  @media (max-width: 576px) {
    width: 240px;
    position: absolute;
    top: 0;
    background-color: #0E243E;
    height: 100vh;
    padding-top: 56px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    transition: right 0.2s ease-in;
    right: ${({ isOpen }): string => (isOpen ? '0' : '-240px')};
  }
`;

export const StyledMenu = styled(Menu)`
  &&& {
    background-color: #0E243E;
  }

  @media (min-width: 576px) {
    width: 100%;
    justify-content: center;
    border-bottom: 0;

    .ant-menu-submenu-title {
      color: #ABB2BB;
    }

    &&& .ant-menu-item {
      color: #ABB2BB;
      padding: 0 26px;

      &:after {
        left: 0;
        right: 0;
        border-width: 4px;
      }
    }

    &&& .ant-menu-item-selected {
      color: #FFFFFF;

      &:after {
        border-bottom: 4px solid #1890FF;
      }
    }

    &&& .ant-menu-item:hover {
      color: #FFFFFF;

      &:after {
        border-bottom: 4px solid #1890FF;
      }
    }
  }
`;

export const UserContainer = styled.div`
  min-width: 200px;
  text-align: right;
`;

export const StyledDropdown = styled(Dropdown)`
  border: 0;
  color: #ABB2BB;
  background-color: #0E243E;

  :hover, :active, :focus {
    color: #FFFFFF;
  }
`;
