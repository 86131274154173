import { Form } from 'antd';
import styled from 'styled-components';

export const StyledForm = styled(Form)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 24px;
  column-gap: 40px;

  @media only screen and (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;
