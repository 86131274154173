import axios from '../http-common';

import { UserType } from '@/types/auth';

export const authorize = async (email: string, password: string): Promise<void> => {
  try {
    await axios.post('/auth/login', { email, password });
    localStorage.setItem('user', 'logged');
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const getUserData = async (): Promise<UserType> => {
  try {
    const { data } = await axios.get<UserType>('/auth/me');
    return data;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const logout = async (): Promise<void> => {
  try {
    await axios.get('/auth/logout');
    localStorage.removeItem('user');
  } catch (error) {
    throw new Error((error as Error).message);
  }
};
