import { Button, Form, FormInstance, Input, Select } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';

import { StyledModal, StyledFormItem } from '@/components/Styled';

interface Props {
  form: FormInstance;
  onCancel: ()=> void;
  onFinish: ()=> void;
  open: boolean;
  isEdit: boolean;
  permissions: DefaultOptionType[];
  isLoading: boolean;
}

const ManageUserModal = ({ form, onCancel, onFinish, open, isEdit, permissions, isLoading }: Props): JSX.Element => (
  <StyledModal
    footer={[
      <Button
        key="cancel"
        onClick={onCancel}
      >
        Anuluj
      </Button>,
      <Button
        key="submit"
        loading={isLoading}
        onClick={form.submit}
        type="primary"
      >
        Zatwierdź
      </Button>
    ]}
    maskClosable={false}
    onCancel={onCancel}
    onOk={form.submit}
    open={open}
    title={isEdit ? 'Edytuj użytkownika' : 'Dodaj użytkownika'}
  >
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      requiredMark={false}
      validateTrigger="onBlur"
    >
      <StyledFormItem
        label="Imię"
        name="first_name"
        rules={[{ required: true, message: 'Imię jest wymagane' }]}
      >
        <Input disabled={isLoading} />
      </StyledFormItem>
      <StyledFormItem
        label="Nazwisko"
        name="last_name"
        rules={[{ required: true, message: 'Nazwisko jest wymagane' }]}
      >
        <Input disabled={isLoading} />
      </StyledFormItem>
      <StyledFormItem
        label="Adres email"
        name="email"
        rules={[
          {
            required: true,
            message: 'Adres email jest wymagany'
          },
          {
            type: 'email',
            message: 'Wprowadzono email o niepoprawnym formacie'
          }
        ]}
      >
        <Input disabled={isLoading} />
      </StyledFormItem>
      <StyledFormItem
        label={isEdit ? 'Hasło (pozostaw puste w razie braku chęci zmiany)' : 'Hasło'}
        name="password"
        rules={[{ required: !isEdit && true, message: 'Hasło jest wymagane' }]}
      >
        <Input.Password disabled={isLoading} />
      </StyledFormItem>
      <StyledFormItem
        label="Uprawnienia"
        name="permissions"
        rules={[{ required: true, message: 'Co najmniej jedno uprawnienie jest wymagane' }]}
      >
        <Select
          disabled={isLoading}
          maxTagCount="responsive"
          mode="multiple"
          options={permissions}
          placeholder="Wybierz"
        />
      </StyledFormItem>
    </Form>
  </StyledModal>
);

export default ManageUserModal;
