import axios from '../http-common';

import { APIReportType, ReportTableType, CreateReportType } from '@/types/reports';

export const getReports = async (): Promise<ReportTableType[]> => {
  try {
    const { data } = await axios.get<APIReportType[]>('/reports');
    return data.map(({ id, ...others }) => ({
      key: id,
      ...others
    }));
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const createReport = async (report: CreateReportType): Promise<void> => {
  try {
    await axios.post('/reports', report);
  } catch (error) {
    throw new Error((error as Error).message);
  }
};
