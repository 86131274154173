import { Tabs } from 'antd';
import styled from 'styled-components';

export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 0;
  }
`;

export const Card = styled.div`
  background-color: #FFFFFF;
  padding: 40px;
  border: 1px solid #F0F0F0;
`;
