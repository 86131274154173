import dayjs from 'dayjs';
import queryString from 'query-string';

import axios from '../http-common';

import { APILotsType, LotsTableType, LotsFormType, LotOptionType } from '@/types/lots';

export const getLots = async (): Promise<LotsTableType[]> => {
  try {
    const { data } = await axios.get<APILotsType[]>('/lots');

    return data.map(({ ts, ...others }) => ({
      key: ts,
      date: dayjs(ts).format('DD.MM.YYYY HH:mm'),
      ...others
    }));
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const getFieldValue = async (name: string): Promise<LotOptionType[]> => {
  const customQuery = queryString.stringify({
    ts1: dayjs().subtract(7, 'day')
      .unix() * 1000,
    ts2: dayjs().unix() * 1000
  }, { sort: false, arrayFormat: 'separator' });

  try {
    const { data } = await axios.get<string[]>(`/lots/fields/${name}?${customQuery}`);

    return data.map((element) => ({
      value: String(element)
    }));
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const createLot = async ({ key, startDate, ...others }: LotsFormType): Promise<void> => {
  try {
    await axios.post('/lots', {
      ts: dayjs(startDate).unix() * 1000,
      ...others
    });
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const updateLot = async ({ key, startDate, ...others }: LotsFormType): Promise<void> => {
  try {
    await axios.put(`/lots/${key}`, {
      ts: dayjs(startDate).unix() * 1000,
      ...Object.fromEntries(Object.entries(others).filter((item) => item[1] !== null && item[1] !== undefined))
    });
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const deleteLot = async (id: number): Promise<void> => {
  try {
    await axios.delete(`/lots/${id}`);
  } catch (error) {
    throw new Error((error as Error).message);
  }
};
