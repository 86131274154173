import { Modal } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  margin: 0 24px;

  .ant-modal-body {
    padding: 0;
    height: calc(100vh - 160px);
    overflow: auto;
  }
`;
