import { Form, FormInstance, Input } from 'antd';
import dayjs from 'dayjs';
import { useMemo } from 'react';

import { StyledModal, StyledFormItem } from '@/components/Styled';
import { SamplesCategoriesType } from '@/types/sample';

interface Props {
  form: FormInstance;
  onCancel: ()=> void;
  onFinish: ()=> void;
  open: boolean;
  timestamp: number;
  selectedFields: SamplesCategoriesType;
}

const returnFieldName = (key: string): string => {
  switch (key) {
    case 'gname': {
      return 'Nazwa';
    }
    case 'grm': {
      return 'GRM';
    }
    case 'lot': {
      return 'LOT';
    }
    case 'station': {
      return 'Station';
    }
    case 'numpalet': {
      return 'Nr palety';
    }
    case 'extnumord': {
      return 'Extnumord';
    }
    case 'batch': {
      return 'Batch';
    }
    default: {
      return '';
    }
  }
};

const GenerateReportModal = ({ form, onCancel, onFinish, open, timestamp, selectedFields }: Props): JSX.Element => {
  const selectedFieldsString = useMemo(() => Object.entries(selectedFields).map(([key, value]) => (key !== 'daterange' ? ` ${returnFieldName(key)}: ${value.join(', ')}` : undefined))
    .filter((value) => value)
    .flat()
    .join('; '), [selectedFields]);

  return (
    <StyledModal
      okText="Zatwierdź"
      onCancel={onCancel}
      onOk={form.submit}
      open={open}
      title="Wygeneruj raport"
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        requiredMark={false}
      >
        <StyledFormItem
          initialValue={selectedFieldsString.length ? `Raport - ${dayjs(timestamp).format('DD.MM.YYYY')};${selectedFieldsString}` : `Raport - ${dayjs(timestamp).format('DD.MM.YYYY')}`}
          label="Nazwa raportu do wygenerowania"
          name="name"
          rules={[{ required: true, message: 'Nazwa raportu jest wymagana' }]}
        >
          <Input />
        </StyledFormItem>
      </Form>
    </StyledModal>
  );
};

export default GenerateReportModal;
