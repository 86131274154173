import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { StyledModal, Container, PreviewImage, StyledButton, IconContainer, StyledIcon, ButtonContainer } from './styles';

interface Props {
  open: boolean;
  src: string;
  notSelectable?: boolean;
  previewModalSubmitState?: boolean;
  onCancel: ()=> void;
  onOk?: ()=> void;
  switchImage: (direction: string)=> void;
}

const ImagePreviewModal = ({ open, src, notSelectable, previewModalSubmitState, onCancel, onOk, switchImage }: Props): JSX.Element => (
  <div onKeyDown={(e): void => switchImage(e.code)}>
    <StyledModal
      centered
      footer={notSelectable
        ? null
        : [
          (previewModalSubmitState && (
            <IconContainer key="selected">
              <StyledIcon />
              Zaznaczono do raportu
            </IconContainer>
          )),
          <ButtonContainer key="buttons">
            <StyledButton onClick={onCancel}>
              Zamknij
            </StyledButton>
            <StyledButton
              onClick={onOk}
              type="primary"
            >
              { previewModalSubmitState ? 'Odznacz zdjęcie' : 'Zaznacz zdjęcie' }
            </StyledButton>
          </ButtonContainer>
        ]}
      onCancel={onCancel}
      open={open}
      title="Podgląd"
      width="auto"
      zIndex={1001}
    >
      <Container>
        <StyledButton
          icon={<LeftOutlined />}
          onClick={(): void => switchImage('ArrowLeft')}
        />
        <PreviewImage src={src} />
        <StyledButton
          icon={<RightOutlined />}
          onClick={(): void => switchImage('ArrowRight')}
        />
      </Container>
    </StyledModal>
  </div>
);

export default ImagePreviewModal;
