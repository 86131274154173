import dayjs from 'dayjs';

export const date = {
  start: dayjs().startOf('day')
    .add(4, 'hour')
    .unix() * 1000,
  end: dayjs().endOf('day')
    .unix() * 1000
};

export const previousDate = {
  start: dayjs().subtract(1, 'day')
    .startOf('day')
    .add(4, 'hour')
    .unix() * 1000,
  end: dayjs().startOf('day')
    .add(4, 'hour')
    .unix() * 1000
};
