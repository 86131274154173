import axios from 'axios';

import customMessage from './components/Message';

import CONFIG from '@/config';

const instance = axios.create({
  baseURL: `${CONFIG.apiLink}`,
  timeout: 30000,
  withCredentials: true,
  headers: {
    'Content-type': 'application/json'
  }
});

export const interceptorResponse = (handleUnauthorize: ()=> void): void => {
  instance.interceptors.response.use((response) => response, (error) => {
    switch (error.response.status) {
      case 400:
      case 500:
        customMessage('Wystąpił nieoczekiwany błąd, spróbuj ponownie później!', 'error', 5);
        break;
      case 401:
        handleUnauthorize();
        if (localStorage.getItem('user')) {
          customMessage('Twoja sesja wygasła, musisz zalogować się ponownie!', 'error', 5);
          localStorage.removeItem('user');
        }
        break;
      case 403:
        customMessage('Wprowadzono niepoprawny email lub hasło!', 'error', 5);
        break;
      default:
        customMessage(error, 'error', 5);
        break;
    }
    return Promise.reject(error);
  });
};

export default instance;
