import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useWindowSize } from '@react-hook/window-size';
import { Button, Form, Layout, Modal, Space, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Card } from './styles';

import customMessage from '../Message';
import ManageLot from '../Modals/ManageLot';
import { Header, HeaderContainer, Link } from '../Styled';

import CONFIG, { modules } from '@/config';
import { createLot, deleteLot, getFieldValue, getLots, updateLot } from '@/services/lots';
import { LotsTableType, LotsFormType, LotOptionType } from '@/types/lots';

const { Content } = Layout;
const { confirm } = Modal;

const Lots = (): JSX.Element => {
  const [data, setData] = useState<LotsTableType[]>([]);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm<LotsFormType>();
  const [options, setOptions] = useState<LotOptionType[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isEdit = useRef(false);
  const focusedField = useRef('');

  const navigate = useNavigate();

  const [width, height] = useWindowSize();

  const filteredColumns = useMemo(() => {
    const columns: ColumnsType<LotsTableType> = [
      {
        title: 'Data',
        dataIndex: 'date',
        key: 'date',
        fixed: 'left',
        sorter: (a, b) => b.key - a.key,
        sortDirections: ['descend', 'ascend'],
        defaultSortOrder: 'ascend',
        width: 140
      },
      {
        title: 'Nazwa',
        dataIndex: 'name',
        key: 'name',
        width: 220,
        render: (value) => <Link>{ value }</Link>,
        onCell: (record) => ({
          onClick: (): void => {
            navigateToAnalysis(record.key, record.name, 'gname');
          }
        })
      },
      {
        title: 'GRM',
        dataIndex: 'grm',
        key: 'grm',
        width: 240,
        render: (value) => <Link>{ value }</Link>,
        onCell: (record) => ({
          onClick: (): void => {
            navigateToAnalysis(record.key, record.grm, 'grm');
          }
        })
      },
      {
        title: 'Waga GRM [kg]',
        key: 'weigthgrm',
        dataIndex: 'weigthgrm'
      },
      {
        title: 'LOT',
        key: 'LOT',
        dataIndex: 'LOT',
        render: (value) => <Link>{ value }</Link>,
        onCell: (record) => ({
          onClick: (): void => {
            navigateToAnalysis(record.key, record.LOT, 'lot');
          }
        })
      },
      {
        title: 'Station',
        key: 'station',
        dataIndex: 'station',
        render: (value) => <Link>{ value }</Link>,
        onCell: (record) => ({
          onClick: (): void => {
            navigateToAnalysis(record.key, record.station, 'station');
          }
        })
      },
      modules.batch
        ? {
          title: 'Batch',
          key: 'prodbatch',
          dataIndex: 'prodbatch',
          render: (value) => <Link>{ value }</Link>,
          onCell: (record) => ({
            onClick: (): void => {
              navigateToAnalysis(record.key, record.prodbatch, 'batch');
            }
          })
        }
        : {},
      modules.extnumord
        ? {
          title: 'Extnumord',
          key: 'extnumord',
          dataIndex: 'extnumord',
          render: (value) => <Link>{ value }</Link>,
          onCell: (record) => ({
            onClick: (): void => {
              navigateToAnalysis(record.key, record.extnumord, 'extnumord');
            }
          })
        }
        : {},
      modules.numpalet
        ? {
          title: 'Nr palety',
          key: 'numpalet',
          dataIndex: 'numpalet',
          render: (value) => <Link>{ value }</Link>,
          onCell: (record) => ({
            onClick: (): void => {
              navigateToAnalysis(record.key, record.numpalet, 'numpalet');
            }
          })
        }
        : {},
      CONFIG.manageLots
        ? {
          title: 'Akcje',
          key: 'actions',
          fixed: 'right',
          width: width > 576 ? 200 : 100,
          render: (_, record) => (
            <Space size="small">
              <Button
                icon={<EditOutlined />}
                onClick={(): Promise<void> => handleEdit(record)}
                size={width > 576 ? 'small' : 'middle'}
                type="link"
              >
                { width > 576 && 'Edytuj' }
              </Button>
              <Button
                danger
                icon={<DeleteOutlined />}
                onClick={(): void => removeLotModal(record.key)}
                size={width > 576 ? 'small' : 'middle'}
                type="link"
              >
                { width > 576 && 'Usuń' }
              </Button>
            </Space>
          )
        }
        : {}
    ];

    return columns.filter((({ key }) => key));
  }, [data]);

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, CONFIG.refreshTime);
    return () => clearInterval(interval);
  }, []);

  const navigateToAnalysis = (ts: number, value: string | number, category: string): void => {
    if (category === 'grm') {
      const filteredGRM = data.filter((element) => element.grm === value);
      navigate(`/analysis?ts1=${filteredGRM[filteredGRM.length - 1].key}&ts2=${
        dayjs(filteredGRM[0].key).hour(23)
          .minute(55)
          .unix() * 1000}&${category}=${value}`);
    } else {
      navigate(`/analysis?ts1=${dayjs(ts).hour(4)
        .minute(0)
        .unix() * 1000}&ts2=${dayjs(ts).hour(23)
        .minute(55)
        .unix() * 1000}&${category}=${value}`);
    }
  };

  const fetchData = async (): Promise<void> => {
    try {
      setLoading(true);
      setData(await getLots());
    } catch (error) {
      console.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      setLoading(true);
      if (isEdit.current) {
        await updateLot(form.getFieldsValue(true));
        customMessage('Pomyślnie zedytowano etykietę!', 'success', 2);
      } else {
        await createLot(form.getFieldsValue(true));
        customMessage('Pomyślnie dodano etykietę!', 'success', 2);
      }
      setData(await getLots());
      setIsModalOpen(false);
      isEdit.current = false;
      form.resetFields();
    } catch (error) {
      console.error((error as Error).message);
      customMessage('Wystąpił nieoczekiwany błąd, spróbuj ponownie później!', 'error', 5);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = (): void => {
    setIsModalOpen(false);
    isEdit.current = false;
    form.resetFields();
  };

  const handleEdit = async (record: LotsTableType): Promise<void> => {
    form.setFieldsValue({
      key: record.key,
      startDate: dayjs(record.key),
      name: record.name,
      grm: record.grm,
      LOT: record.LOT,
      station: record.station,
      weigthgrm: String(record.weigthgrm),
      prodbatch: modules.batch ? String(record.prodbatch) : null,
      extnumord: modules.extnumord ? String(record.extnumord) : null,
      numpalet: modules.numpalet ? String(record.numpalet) : null
    });
    isEdit.current = true;
    setIsModalOpen(true);
  };

  const handleRemove = async (key: number): Promise<void> => {
    try {
      setLoading(true);
      await deleteLot(key);
      setData(await getLots());
      customMessage('Pomyślnie usunięto etykietę!', 'success', 2);
    } catch (error) {
      console.error((error as Error).message);
      customMessage('Nie udało się usunąć etykiety, spróbuj ponownie później!', 'error', 5);
    } finally {
      setLoading(false);
    }
  };

  const fetchOptions = async (name: string): Promise<void> => {
    try {
      focusedField.current = name;
      setOptions(await getFieldValue(name));
    } catch (error) {
      console.error((error as Error).message);
      customMessage('Nie udało się pobrać listy podpowiedzi!', 'error', 3);
    }
  };

  const removeChars = (name: string, value: string): void => {
    switch (name) {
      case 'numpalet':
      case 'prodbatch':
      case 'extnumord': {
        form.setFieldValue(name, value.match(/[+-]?([0-9])?[0-9]+/)?.[0]);
        break;
      }
      case 'weigthgrm': {
        form.setFieldValue(name, value.match(/[+-]?([0-9]*[.])?[0-9]+/)?.[0]);
        break;
      }
    }
  };

  const removeLotModal = (key: number): void => {
    confirm({
      title: 'Usuń etykietę',
      icon: <ExclamationCircleOutlined />,
      content: 'Czy na pewno chcesz usunąć etykietę?',
      okText: 'Usuń',
      okType: 'danger',
      onOk() {
        handleRemove(key);
      }
    });
  };

  return (
    <Content>
      <HeaderContainer>
        <Header>
          Etykiety
        </Header>
        {
          CONFIG.manageLots && (
            <Button
              onClick={(): void => setIsModalOpen(true)}
              type="primary"
            >
              Dodaj etykietę
            </Button>
          )
        }
      </HeaderContainer>
      <Card>
        <Table
          columns={filteredColumns}
          dataSource={data}
          loading={loading}
          pagination={{ responsive: true }}
          scroll={{ x: 1400, y: height - 302 }}
        />
      </Card>
      <ManageLot
        focusedField={focusedField.current}
        form={form}
        getOptions={fetchOptions}
        isEdit={isEdit.current}
        isLoading={loading}
        onCancel={handleCancel}
        onFinish={handleSubmit}
        open={isModalOpen}
        options={options}
        removeChars={removeChars}
      />
    </Content>
  );
};

export default Lots;
