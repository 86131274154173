import { useWindowHeight } from '@react-hook/window-size';
import { Descriptions, Radio, Spin } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { CustomDescription, DescriptionContainer, SelectorRow } from './styles';

import { date, previousDate } from '@/components/CurrentData/Dates';
import Line from '@/components/Nivo/Line';
import { Container, Label, SelectorContainer, TabContainer } from '@/components/Styled';
import CONFIG, { modules } from '@/config';
import { getSamplesCurrentData, getSummaryData } from '@/services/current-data';
import { SamplesCurrentData } from '@/types/current-data';
import { LineData } from '@/types/sample';

interface Props {
  chartHeight?: number;
}

const options = [
  { label: 'Ostatnie 2h', value: '2h' },
  { label: 'Cały dzień', value: 'day' }
];

const lastTwoHours = {
  start: dayjs().subtract(2, 'hours')
    .unix() * 1000,
  end: dayjs().unix() * 1000
};

const CurrentSummary = ({ chartHeight }: Props): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<LineData[]>();
  const [samplesCurrentData, setSamplesCurrentData] = useState<SamplesCurrentData>();
  const [selectedTime, setSelectedTime] = useState('day');

  const height = useWindowHeight();

  useEffect(() => {
    fetchChartData();
  }, [selectedTime]);

  useEffect(() => {
    fetchSamplesCurrentData();

    const interval = setInterval(() => {
      fetchSamplesCurrentData();
    }, CONFIG.refreshTime);
    return () => clearInterval(interval);
  }, []);

  const fetchChartData = async (): Promise<void> => {
    try {
      if (selectedTime === '2h') {
        setData(await getSummaryData(lastTwoHours.start, lastTwoHours.end, 10));
      } else if (dayjs().isBefore(dayjs().startOf('day')
        .add(4, 'hour'))) {
        setData(await getSummaryData(previousDate.start, previousDate.end, 10));
      } else {
        setData(await getSummaryData(date.start, date.end, 10));
      }
    } catch (error) {
      console.error((error as Error).message);
    }
  };

  const fetchSamplesCurrentData = async (): Promise<void> => {
    try {
      setLoading(true);
      if (dayjs().isBefore(dayjs().startOf('day')
        .add(4, 'hour'))) {
        setSamplesCurrentData(await getSamplesCurrentData(previousDate.start, previousDate.end));
      } else {
        setSamplesCurrentData(await getSamplesCurrentData(date.start, date.end));
      }
    } catch (error) {
      console.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <TabContainer>
      <Spin spinning={loading}>
        <DescriptionContainer>
          <Label>
            Ostatni przetworzony
          </Label>
          <CustomDescription column={{ xs: 1, sm: 2, lg: 4 }}>
            <Descriptions.Item label="Nazwa">{ samplesCurrentData?.name }</Descriptions.Item>
            <Descriptions.Item label="GRM">{ samplesCurrentData?.grm }</Descriptions.Item>
            <Descriptions.Item label="LOT">{ samplesCurrentData?.LOT }</Descriptions.Item>
            <Descriptions.Item label="Station">{ samplesCurrentData?.station }</Descriptions.Item>
            { modules.batch && <Descriptions.Item label="Batch">{ samplesCurrentData?.prodbatch }</Descriptions.Item> }
            { modules.extnumord && <Descriptions.Item label="Extnumord">{ samplesCurrentData?.extnumord }</Descriptions.Item> }
            { modules.numpalet && <Descriptions.Item label="Nr palety">{ samplesCurrentData?.numpalet }</Descriptions.Item> }
          </CustomDescription>
        </DescriptionContainer>
        <SelectorContainer>
          <SelectorRow>
            <Label>
              Ostatni odczyt
            </Label>
            <CustomDescription column={{ xs: 1, sm: 1, md: 1, lg: 2 }}>
              <Descriptions.Item
                label="Data"
                style={{ textTransform: 'capitalize' }}
              >
                { dayjs(samplesCurrentData?.ts).format('dddd, DD.MM.YYYY - HH:mm') }
              </Descriptions.Item>
              <Descriptions.Item label="Liczba próbek">{ samplesCurrentData?.count }</Descriptions.Item>
            </CustomDescription>
          </SelectorRow>
          <SelectorRow>
            <Label>
              Wybrany czas
            </Label>
            <Radio.Group
              onChange={({ target }): void => setSelectedTime(target.value)}
              options={options}
              value={selectedTime}
            />
          </SelectorRow>
        </SelectorContainer>
      </Spin>
      <Container style={{ height: chartHeight || height - 264, minHeight: 460 }}>
        { data && (
          <Line
            chartData={data}
            selectedTime={selectedTime}
          />
        ) }
      </Container>
    </TabContainer>
  );
};

export default CurrentSummary;
