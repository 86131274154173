import { useWindowWidth } from '@react-hook/window-size';
import { Button, Form } from 'antd';
import { useEffect, useState } from 'react';

import DefectCard from './DefectCard';
import { BarContainer, Label, Content, Placeholder, StyledButtonGroup } from './styles';

import customMessage from '@/components/Message';
import GenerateReportModal from '@/components/Modals/GenerateReport';
import { createReport } from '@/services/reports';
import { setSelected } from '@/store/features/samplesState';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { CreateReportType, ReportFormType } from '@/types/reports';

interface SelectedImageType {
  id: number;
  src: string;
  state: boolean;
  defect: string;
}

interface DefectType {
  label: string;
  value: string;
}

interface Props {
  defects: DefectType[];
  selectedImagesCount: number;
  selectedToReport: SelectedImageType[];
  attachToReport: ()=> void;
  unselectAllImages: ()=> void;
  clearSelectedImages: ()=> void;
  openDefectModal: (label: string, value: string)=> void;
}

const SelectionBar = ({
  defects,
  selectedImagesCount,
  selectedToReport,
  attachToReport,
  unselectAllImages,
  clearSelectedImages,
  openDefectModal
}: Props): JSX.Element => {
  const { date, selectedFields } = useAppSelector(({ samplesState }) => samplesState);
  const { user } = useAppSelector(({ userState }) => userState);
  const dispatch = useAppDispatch();

  const [reportData, setReportData] = useState<CreateReportType>({ ts1: 0, ts2: 0 });
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [form] = Form.useForm<ReportFormType>();

  const nameValue = Form.useWatch('name', form);

  const width = useWindowWidth();

  useEffect(() => {
    setReportData({
      ts1: date.start,
      ts2: date.end,
      status: '3',
      ...selectedFields
    });
  }, [selectedFields]);

  useEffect(() => {
    setReportData((prevState) => ({
      ...prevState,
      ...selectedToReport.reduce((prev: { [key: string]: string[] }, { defect, src }) => {
        prev[`img_${defect}`] = prev[`img_${defect}`] || [];
        prev[`img_${defect}`].push(src);
        return prev;
      }, {})
    }));
  }, [selectedToReport]);

  const showModal = (): void => {
    if (!selectedToReport.length) {
      customMessage('Nie wybrano zdjęć do raportu', 'warning', 2);
    } else {
      setIsReportModalOpen(true);
    }
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      await createReport({ ...reportData, name: nameValue, email: user.email });
      dispatch(setSelected(false));
      customMessage('Raport z wybranymi zdjęciami został pomyślnie wysłany do wygenerowania!', 'success', 5);
    } catch (error) {
      console.error((error as Error).message);
    }
    clearSelectedImages();
    setIsReportModalOpen(false);
  };

  const handleCancel = (): void => {
    form.resetFields();
    setIsReportModalOpen(false);
  };

  return (
    <BarContainer>
      <div>
        <Label>
          Dodane do raportu
        </Label>
        <Content>
          { selectedToReport.length
            ? (
              <>
                {
                  defects.map(({ label, value }) => {
                    const amount = selectedToReport.filter(({ defect }) => defect === value);
                    return amount.length !== 0
                    && (
                      <DefectCard
                        key={value}
                        count={amount.length}
                        openDefectModal={(): void => openDefectModal(label, value)}
                      >
                        { label }
                      </DefectCard>
                    );
                  })
                }
              </>
            )
            : (
              <Placeholder>
                Nie wybrano zdjęć
              </Placeholder>
            ) }
        </Content>
      </div>
      <div>
        <Label>
          { `Zaznaczono: ${selectedImagesCount}` }
        </Label>
        <Content>
          <StyledButtonGroup>
            <Button onClick={attachToReport}>{ width > 576 ? 'Dodaj zaznaczone' : 'Dodaj zazn.' }</Button>
            <Button onClick={unselectAllImages}>{ width > 576 ? 'Odznacz wszystkie' : 'Odznacz wsz.' }</Button>
          </StyledButtonGroup>
          <Button
            onClick={showModal}
            type="primary"
          >
            Wygeneruj
          </Button>
        </Content>
      </div>
      <GenerateReportModal
        form={form}
        onCancel={handleCancel}
        onFinish={handleSubmit}
        open={isReportModalOpen}
        selectedFields={selectedFields}
        timestamp={Math.max(...selectedToReport.map(({ id }) => id))}
      />
    </BarContainer>
  );
};

export default SelectionBar;
