import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Select } from 'antd';
import locale from 'antd/es/date-picker/locale/pl_PL';
import dayjs, { Dayjs } from 'dayjs';
import queryString from 'query-string';
import { RangePickerProps } from 'rc-picker';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Card, StyledFormItem, StyledRangePicker, Row, StyledLastFormItem } from './styles';

import customMessage from '@/components/Message';
import { modules } from '@/config';
import { getSamples, getFieldValue } from '@/services/sample';
import { setDate, setSamplesData, setFieldsValues, setLoading, setSelected } from '@/store/features/samplesState';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { FieldValueType, SamplesFormType } from '@/types/sample';

const { confirm } = Modal;

const Filters = (): JSX.Element => {
  const { isSelected } = useAppSelector(({ samplesState }) => samplesState);
  const dispatch = useAppDispatch();

  const [options, setOptions] = useState<FieldValueType[]>([]);
  const [isFieldLoading, setIsFieldLoading] = useState(false);

  const [form] = Form.useForm<SamplesFormType>();
  const dateRange = Form.useWatch('daterange', form);
  const disabledDate: RangePickerProps<Dayjs>['disabledDate'] = (current) => current && current > dayjs().endOf('day');

  const [searchParams, setSearchParams] = useSearchParams();

  const unixDate = useMemo(() => (
    dateRange
      ? {
        start: dayjs(dateRange[0]).unix() * 1000,
        end: dayjs(dateRange[1]).unix() * 1000
      }
      : {
        start: 0,
        end: 0
      }
  ), [dateRange]);

  useEffect(() => {
    const queryParams = queryString.parse(String(searchParams), { arrayFormat: 'separator' });
    if (Object.keys(queryParams).length) {
      const { ts1, ts2, ...filteredQuery } = queryParams;

      const filteredQueryArr = Object.entries(filteredQuery).reduce(
        (prev, [key, value]) => ({
          ...prev,
          [key]: Array.isArray(value) ? value : [value]
        }),
        {}
      );

      form.setFieldsValue({ daterange: [dayjs.unix(Number(ts1) / 1000), dayjs.unix(Number(ts2) / 1000)], ...filteredQueryArr });
    }
  }, []);

  const loadFieldValue = async (fieldName: string): Promise<void> => {
    setOptions([]);
    const { daterange, ...fieldsValues } = form.getFieldsValue(true);
    try {
      setIsFieldLoading(true);
      setOptions((await getFieldValue(fieldName, unixDate.start, unixDate.end, fieldsValues)));
    } catch (error) {
      console.error((error as Error).message);
    } finally {
      setIsFieldLoading(false);
    }
  };

  const handleOpen = (fieldName: string): void => {
    if (dateRange) {
      loadFieldValue(fieldName);
    } else {
      setOptions([]);
      customMessage('Daty nie zostały wybrane', 'warning', 2);
    }
  };

  const handleSubmit = (): void => {
    if (isSelected) {
      resetSelectedImages();
    } else {
      fetchData();
    }
  };

  const fetchData = async (): Promise<void> => {
    const { daterange, ...fieldsValues } = form.getFieldsValue(true);

    Object.keys(fieldsValues).forEach((key) => {
      if (!fieldsValues[key].length) {
        delete fieldsValues[key];
      }
    });

    dispatch(setDate({ start: unixDate.start, end: unixDate.end }));
    dispatch(setFieldsValues({ ...fieldsValues }));

    try {
      dispatch(setLoading(true));
      const response = await getSamples(unixDate.start, unixDate.end, fieldsValues);
      dispatch(setSamplesData(response));
      if (!response.length) {
        customMessage('Na wybrane daty nie ma danych do wyświetlenia', 'error', 2);
      } else {
        const customQuery = queryString.stringify({
          ts1: unixDate.start,
          ts2: unixDate.end,
          ...fieldsValues,
          daterange: undefined
        }, { sort: false, arrayFormat: 'separator' });

        setSearchParams(customQuery);
      }
    } catch (error) {
      console.error((error as Error).message);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const resetModal = (): void => {
    confirm({
      title: 'Wyczyść pola',
      icon: <ExclamationCircleOutlined />,
      content: 'Czy na pewno chcesz wyczyścić wszystkie pola? Spowoduje to wyczyszczenie wszystkich wczytanych danych!',
      onOk() {
        form.resetFields();
        setOptions([]);
        dispatch(setSamplesData([]));
        setSearchParams({});
      }
    });
  };

  const resetSelectedImages = (): void => {
    confirm({
      title: 'Podejrzyj nowe dane',
      icon: <ExclamationCircleOutlined />,
      content: 'Czy na pewno chcesz podejrzeć inne dane? Spowoduje to usunięcie wszystkich zaznaczonych zdjęć!',
      onOk() {
        dispatch(setSamplesData([]));
        dispatch(setSelected(false));
        fetchData();
      }
    });
  };

  return (
    <Card>
      <Form
        form={form}
        initialValues={{
          daterange: [
            dayjs().subtract((dayjs().day() > 0 && dayjs().day() < 4) ? 4 : 3, 'day')
              .hour(4)
              .minute(0),
            dayjs().hour(23)
              .minute(55)
          ]
        }}
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark={false}
      >
        <Row>
          <StyledFormItem
            label="Przedział dat"
            name="daterange"
            rules={[{ required: true, message: 'Wybranie dat jest wymagane' }]}
            span={2}
            start={1}
          >
            <StyledRangePicker
              disabledDate={disabledDate}
              format="DD.MM.YYYY HH:mm"
              locale={locale}
              showTime={{
                hideDisabledOptions: true,
                defaultValue: [dayjs('04:00', 'HH:mm'), dayjs('23:55', 'HH:mm')],
                minuteStep: 5
              }}
            />
          </StyledFormItem>
        </Row>
        <Row>
          <StyledFormItem
            label="Nazwa partii"
            name="gname"
            span={2}
            start={1}
          >
            <Select
              allowClear
              loading={isFieldLoading}
              maxTagCount="responsive"
              mode="multiple"
              onFocus={(e): void => handleOpen(e.target.id)}
              options={options}
              placeholder="Wybierz"
              showSearch
            />
          </StyledFormItem>
          <StyledFormItem
            label="GRM"
            name="grm"
          >
            <Select
              allowClear
              loading={isFieldLoading}
              maxTagCount="responsive"
              mode="multiple"
              onFocus={(e): void => handleOpen(e.target.id)}
              options={options}
              placeholder="Wybierz"
              showSearch
            />
          </StyledFormItem>
          <StyledFormItem
            label="LOT"
            name="lot"
          >
            <Select
              allowClear
              loading={isFieldLoading}
              maxTagCount="responsive"
              mode="multiple"
              onFocus={(e): void => handleOpen(e.target.id)}
              options={options}
              placeholder="Wybierz"
              showSearch
            />
          </StyledFormItem>
          <StyledFormItem
            label="Station"
            name="station"
          >
            <Select
              allowClear
              loading={isFieldLoading}
              maxTagCount="responsive"
              mode="multiple"
              onFocus={(e): void => handleOpen(e.target.id)}
              options={options}
              placeholder="Wybierz"
              showSearch
            />
          </StyledFormItem>
          { modules.numpalet
          && (
            <StyledFormItem
              label="Nr palety"
              name="numpalet"
            >
              <Select
                allowClear
                loading={isFieldLoading}
                maxTagCount="responsive"
                mode="multiple"
                onFocus={(e): void => handleOpen(e.target.id)}
                options={options}
                placeholder="Wybierz"
                showSearch
              />
            </StyledFormItem>
          ) }
          { modules.extnumord
          && (
            <StyledFormItem
              label="Extnumord"
              name="extnumord"
            >
              <Select
                allowClear
                loading={isFieldLoading}
                maxTagCount="responsive"
                mode="multiple"
                onFocus={(e): void => handleOpen(e.target.id)}
                options={options}
                placeholder="Wybierz"
                showSearch
              />
            </StyledFormItem>
          ) }
          { modules.batch
          && (
            <StyledFormItem
              label="Batch"
              name="batch"
            >
              <Select
                allowClear
                loading={isFieldLoading}
                maxTagCount="responsive"
                mode="multiple"
                onFocus={(e): void => handleOpen(e.target.id)}
                options={options}
                placeholder="Wybierz"
                showSearch
              />
            </StyledFormItem>
          ) }
          <StyledLastFormItem label=" ">
            <Button onClick={resetModal}>Wyczyść</Button>
            <Button
              htmlType="submit"
              type="primary"
            >
              Wyszukaj
            </Button>
          </StyledLastFormItem>
        </Row>
      </Form>
    </Card>
  );
};

export default Filters;
