import { useWindowSize } from '@react-hook/window-size';
import { Button, Space } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TabContainer, Container, Header, StyledCSVLink } from './styles';

import customMessage from '@/components/Message';
import { Link } from '@/components/Styled';
import CONFIG, { modules } from '@/config';
import { useAppSelector } from '@/store/hooks';
import { SamplesTableType } from '@/types/sample';

interface Props {
  tableHeight?: number;
  currentData?: boolean;
}

interface OnCellClick {
  onClick: ()=> void;
}

interface CellStyleType {
  style: CSSProperties;
}

const ColorCell = (value: number): string => {
  if (value >= 50) {
    return '#F8A398';
  }
  if (value >= 45) {
    return '#F5AE9E';
  }
  if (value >= 40) {
    return '#F2B8A3';
  }
  if (value >= 35) {
    return '#EFC2A8';
  }
  if (value >= 30) {
    return '#EBCCAD';
  }
  if (value >= 25) {
    return '#E8D6B2';
  }
  if (value >= 20) {
    return '#E5E0B7';
  }
  if (value >= 15) {
    return '#E2EBBD';
  }
  if (value >= 10) {
    return '#DFF5C2';
  }
  if (value > 0) {
    return '#DCFFC7';
  }
  return '';
};

const returnNumber = (value: string | number): number => (typeof value === 'string' ? Number(value.replace(',', '.')) : value);

const sortStringOrNumber = (a: string | number, b: string | number): number => {
  if ((a === '-' && b !== '-')) {
    return 1;
  }
  if (a !== '-' && b === '-') {
    return -1;
  }
  if (a !== '-' && b !== '-') {
    return returnNumber(b) - returnNumber(a);
  }
  return 0;
};

const SummaryTable = ({ tableHeight, currentData }: Props): JSX.Element => {
  const { date, samplesData, selectedFields } = useAppSelector(({ samplesState }) => samplesState);

  const [filterEmpty, setFilterEmpty] = useState(true);

  const filteredData = useMemo(() => samplesData.filter(({ gname, samples_count }) => gname !== '-' && samples_count > 20), [samplesData]);
  const allColumnsSelected = useMemo(() => !Object.keys(selectedFields).length, [selectedFields]);

  const [width, height] = useWindowSize();

  const navigate = useNavigate();

  useEffect(() => {
    setFilterEmpty(true);
  }, [samplesData]);

  const navigateToAnalysis = (ts: number, value: string | number, category: string): void => {
    if (category === 'grm') {
      const filteredGRM = samplesData.filter((element) => element.grm === value);
      navigate(`/analysis?ts1=${filteredGRM[filteredGRM.length - 1].key}&ts2=${
        dayjs(filteredGRM[0].key).hour(23)
          .minute(55)
          .unix() * 1000}&${category}=${value}`);
    } else {
      navigate(`/analysis?ts1=${dayjs(ts).hour(4)
        .minute(0)
        .unix() * 1000}&ts2=${dayjs(ts).hour(23)
        .minute(55)
        .unix() * 1000}&${category}=${value}`);
    }
  };

  const checkEmpty = (): void => {
    if (samplesData.length === filteredData.length || !filteredData.length) {
      customMessage('Brak dodatkowych wierszy do wyświetlenia!', 'warning', 5);
    } else {
      setFilterEmpty((prev) => !prev);
    }
  };

  const filteredColumns = useMemo(() => {
    const columns: ColumnsType<SamplesTableType> = [
      {
        title: 'Data',
        dataIndex: 'date',
        key: 'date',
        fixed: 'left',
        width: 140,
        sorter: (a, b) => b.key - a.key,
        sortDirections: ['descend', 'ascend'],
        defaultSortOrder: 'ascend'
      },
      (selectedFields.gname || allColumnsSelected)
        ? {
          title: 'Nazwa',
          dataIndex: 'gname',
          key: 'gname',
          fixed: width > 768 && 'left',
          width: 220,
          sorter: (a, b) => b.gname.localeCompare(a.gname),
          sortDirections: ['descend', 'ascend'],
          render: currentData ? (value): JSX.Element => <Link>{ value }</Link> : undefined,
          onCell: currentData
            ? (record): OnCellClick => ({
              onClick: (): void => {
                navigateToAnalysis(record.key, record.gname, 'gname');
              }
            })
            : undefined
        }
        : {},
      (selectedFields.grm || allColumnsSelected)
        ? {
          title: 'GRM',
          dataIndex: 'grm',
          key: 'grm',
          fixed: width > 768 && 'left',
          width: 180,
          sorter: (a, b) => b.grm.localeCompare(a.grm),
          sortDirections: ['descend', 'ascend'],
          render: currentData ? (value): JSX.Element => <Link>{ value }</Link> : undefined,
          onCell: currentData
            ? (record): OnCellClick => ({
              onClick: (): void => {
                navigateToAnalysis(record.key, record.grm, 'grm');
              }
            })
            : undefined
        }
        : {},
      (selectedFields.lot || allColumnsSelected)
        ? {
          title: 'LOT',
          dataIndex: 'lot',
          key: 'lot',
          fixed: (selectedFields.lot && width > 768) && 'left',
          width: 100,
          sorter: (a, b) => b.lot.localeCompare(a.lot),
          sortDirections: ['descend', 'ascend'],
          render: currentData ? (value): JSX.Element => <Link>{ value }</Link> : undefined,
          onCell: currentData
            ? (record): OnCellClick => ({
              onClick: (): void => {
                navigateToAnalysis(record.key, record.lot, 'lot');
              }
            })
            : undefined
        }
        : {},
      (selectedFields.station || allColumnsSelected)
        ? {
          title: 'Station',
          dataIndex: 'station',
          key: 'station',
          fixed: (selectedFields.station && width > 768) && 'left',
          width: 82,
          sorter: (a, b) => b.station.localeCompare(a.station),
          sortDirections: ['descend', 'ascend'],
          render: currentData ? (value): JSX.Element => <Link>{ value }</Link> : undefined,
          onCell: currentData
            ? (record): OnCellClick => ({
              onClick: (): void => {
                navigateToAnalysis(record.key, record.station, 'station');
              }
            })
            : undefined
        }
        : {},
      modules.numpalet && (selectedFields.numpalet || allColumnsSelected)
        ? {
          title: 'Nr palety',
          dataIndex: 'numpalet',
          key: 'numpalet',
          width: 82,
          fixed: (selectedFields.numpalet && width > 768) && 'left',
          sorter: (a, b) => b.numpalet - a.numpalet,
          sortDirections: ['descend', 'ascend'],
          render: currentData ? (value): JSX.Element => <Link>{ value }</Link> : undefined,
          onCell: currentData
            ? (record): OnCellClick => ({
              onClick: (): void => {
                navigateToAnalysis(record.key, record.numpalet, 'numpalet');
              }
            })
            : undefined
        }
        : {},
      modules.extnumord && (selectedFields.extnumord || allColumnsSelected)
        ? {
          title: 'Extnumord',
          dataIndex: 'extnumord',
          key: 'extnumord',
          fixed: (selectedFields.extnumord && width > 768 && 'left'),
          width: 100,
          sorter: (a, b) => sortStringOrNumber(a.extnumord, b.extnumord),
          sortDirections: ['descend', 'ascend'],
          render: currentData ? (value): JSX.Element => <Link>{ value }</Link> : undefined,
          onCell: currentData
            ? (record): OnCellClick => ({
              onClick: (): void => {
                navigateToAnalysis(record.key, record.extnumord, 'extnumord');
              }
            })
            : undefined
        }
        : {},
      modules.batch && (selectedFields.batch || allColumnsSelected)
        ? {
          title: 'Batch',
          dataIndex: 'batch',
          key: 'batch',
          fixed: (selectedFields.batch && width > 768) && 'left',
          width: 82,
          sorter: (a, b) => b.batch - a.batch,
          sortDirections: ['descend', 'ascend'],
          render: currentData ? (value): JSX.Element => <Link>{ value }</Link> : undefined,
          onCell: currentData
            ? (record): OnCellClick => ({
              onClick: (): void => {
                navigateToAnalysis(record.key, record.batch, 'batch');
              }
            })
            : undefined
        }
        : {},
      {
        title: 'Ilość\nfiletów',
        dataIndex: 'samples_count',
        key: 'samples_count',
        width: 90,
        sorter: (a, b) => b.samples_count - a.samples_count,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: 'Waga GRM\n[kg]',
        dataIndex: 'grm_total',
        key: 'grm_total',
        width: 90,
        sorter: (a, b) => sortStringOrNumber(a.grm_total, b.grm_total),
        sortDirections: ['descend', 'ascend']
      },
      CONFIG.displayWeight
        ? {
          title: 'Filety\n[kg]',
          dataIndex: 'weight_sum',
          key: 'weight_sum',
          width: 90,
          sorter: (a, b) => sortStringOrNumber(a.weight_sum, b.weight_sum),
          sortDirections: ['descend', 'ascend']
        }
        : {},
      CONFIG.displayWeight
        ? {
          title: 'Mean w.\n[kg]',
          dataIndex: 'weight_avg',
          key: 'weight_avg',
          width: 90,
          sorter: (a, b) => sortStringOrNumber(a.weight_avg, b.weight_avg),
          sortDirections: ['descend', 'ascend']
        }
        : {},
      {
        title: 'Gaping\n[%]',
        dataIndex: 'gaping_percentage',
        key: 'gaping_percentage',
        width: 82,
        sorter: (a, b) => sortStringOrNumber(a.gaping_percentage, b.gaping_percentage),
        sortDirections: ['descend', 'ascend'],
        onCell: ({ gaping_percentage }): CellStyleType => ({
          style: { background: ColorCell(returnNumber(gaping_percentage)) }
        })
      },
      CONFIG.displayWeight
        ? {
          title: 'Gaping\n[kg]',
          dataIndex: 'gaping_weight',
          key: 'gaping_weight',
          width: 82,
          sorter: (a, b) => sortStringOrNumber(a.gaping_weight, b.gaping_weight),
          sortDirections: ['descend', 'ascend']
        }
        : {},
      {
        title: 'Melan\n[%]',
        dataIndex: 'melanin_percentage',
        key: 'melanin_percentage',
        width: 82,
        sorter: (a, b) => sortStringOrNumber(a.melanin_percentage, b.melanin_percentage),
        sortDirections: ['descend', 'ascend'],
        onCell: ({ melanin_percentage }): CellStyleType => ({
          style: { background: ColorCell(returnNumber(melanin_percentage)) }
        })
      },
      CONFIG.displayWeight
        ? {
          title: 'Melan\n[kg]',
          dataIndex: 'melanin_weight',
          key: 'melanin_weight',
          width: 82,
          sorter: (a, b) => sortStringOrNumber(a.melanin_weight, b.melanin_weight),
          sortDirections: ['descend', 'ascend']
        }
        : {},
      {
        title: 'Krwiak\n[%]',
        dataIndex: 'hemat_percentage',
        key: 'hemat_percentage',
        width: 82,
        sorter: (a, b) => sortStringOrNumber(a.hemat_percentage, b.hemat_percentage),
        sortDirections: ['descend', 'ascend'],
        onCell: ({ hemat_percentage }): CellStyleType => ({
          style: { background: ColorCell(returnNumber(hemat_percentage)) }
        })
      },
      CONFIG.displayWeight
        ? {
          title: 'Krwiak\n[kg]',
          dataIndex: 'hemat_weight',
          key: 'hemat_weight',
          width: 82,
          sorter: (a, b) => sortStringOrNumber(a.hemat_weight, b.hemat_weight),
          sortDirections: ['descend', 'ascend']
        }
        : {},
      {
        title: 'Przerost\n[%]',
        dataIndex: 'overgrowth_percentage',
        key: 'overgrowth_percentage',
        width: 82,
        sorter: (a, b) => sortStringOrNumber(a.overgrowth_percentage, b.overgrowth_percentage),
        sortDirections: ['descend', 'ascend'],
        onCell: ({ overgrowth_percentage }): CellStyleType => ({
          style: { background: ColorCell(returnNumber(overgrowth_percentage)) }
        })
      },
      CONFIG.displayWeight
        ? {
          title: 'Przerost\n[kg]',
          dataIndex: 'overgrowth_weight',
          key: 'overgrowth_weight',
          width: 82,
          sorter: (a, b) => sortStringOrNumber(a.overgrowth_weight, b.overgrowth_weight),
          sortDirections: ['descend', 'ascend']
        }
        : {},
      {
        title: 'Pgmnt Średni',
        dataIndex: 'pigment_avg',
        key: 'pigment_avg',
        width: 82
      },
      {
        title: 'Pgmnt poza zakr. [%]',
        dataIndex: 'pigment_outlr_percentage',
        key: 'pigment_outlr_percentage',
        width: 94
      },
      {
        title: 'Perr',
        dataIndex: 'perr',
        key: 'perr',
        width: 82
      },
      {
        title: 'GateL',
        dataIndex: 'gate_l',
        key: 'gate_l',
        width: 82
      }
    ];

    return columns.filter((({ key }) => key));
  }, [selectedFields, allColumnsSelected]);

  return (
    <TabContainer>
      <Container>
        <Header>Podsumowanie</Header>
        <Space size="middle">
          <Button
            onClick={checkEmpty}
            onFocus={(e): React.FocusEvent<Element> => e.target.blur()}
            style={{ width: 200 }}
          >
            { filterEmpty ? 'Wyświetl wszystkie wiersze' : 'Ukryj nieistotne wiersze' }
          </Button>
          <StyledCSVLink
            data={(filterEmpty && filteredData.length) ? filteredData : samplesData}
            filename={`${dayjs(date.start).format('DD.MM.YYYY')} - ${dayjs(date.end).format('DD.MM.YYYY')}.csv`}
            headers={
              filteredColumns.map(({ title, key }) => ({
                label: String(title),
                key: String(key)
              }))
            }
            separator=";"
          >
            Pobierz jako CSV
          </StyledCSVLink>
        </Space>
      </Container>
      <Table
        className="narrow summary-table"
        columns={filteredColumns}
        dataSource={(filterEmpty && filteredData.length) ? filteredData : samplesData}
        pagination={false}
        scroll={{ x: CONFIG.displayWeight ? 2400 : 2000, y: tableHeight || height - 324 }}
      />
    </TabContainer>
  );
};

export default SummaryTable;
