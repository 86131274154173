import { Form } from 'antd';
import styled from 'styled-components';

import { DatePicker } from '../../Pickers';

const { RangePicker } = DatePicker;

interface StyledFormItemType {
  start?: number;
  span?: number;
}

export const Card = styled.div`
  background-color: #FFFFFF;
  padding: 16px 24px 0px 24px;
  border: 1px solid #F0F0F0;
  margin-bottom: 16px;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-column-gap: 24px;

  @media (max-width: 1400px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @media (max-width: 576px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const StyledFormItem = styled(Form.Item)<StyledFormItemType>`
  font-weight: 500;

  .ant-form-item-label {
    padding-bottom: 4px;
  }

  ${({ start, span }): string => (start && span
    ? `grid-column: ${start} / span ${span};`
    : '')
}
`;

export const StyledLastFormItem = styled(Form.Item)`
  grid-column: span 1 / -1;
  text-align: right;

  .ant-form-item-label {
    padding-bottom: 4px;
  }

  button + button {
    margin-left: 16px;
  }

  @media (max-width: 992px) {
    grid-column: span 2 / -1;
  }
`;

export const StyledRangePicker = styled(RangePicker)`
  width: 100%;

  input {
    text-align: center;
  }
`;
