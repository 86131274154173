export default {
  apiLink: process.env.REACT_APP_API_LINK,
  socketLink: process.env.REACT_APP_SOCKET_LINK || 'http://localhost:4000',
  bucketLink: process.env.REACT_APP_BUCKET_LINK,
  refreshTime: Number(process.env.REACT_APP_REFRESH_TIME) || 60000,
  manageLots: process.env.REACT_APP_MANAGE_LOTS?.toLowerCase() === 'true' || false,
  displayWeight: process.env.REACT_APP_DISPLAY_WEIGHT?.toLowerCase() === 'true' || false,
  clientName: process.env.REACT_APP_CLIENT_NAME?.toLowerCase() || ''
};

export const modules = {
  numpalet: process.env.REACT_APP_MODULE_NUMPALET?.toLowerCase() === 'true' || false,
  extnumord: process.env.REACT_APP_MODULE_EXTNUMORD?.toLowerCase() === 'true' || false,
  batch: process.env.REACT_APP_MODULE_BATCH?.toLowerCase() === 'true' || false
};
