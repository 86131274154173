import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useWindowSize } from '@react-hook/window-size';
import { Button, Form, Layout, Modal, Space, Table, Tag, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useMemo, useState } from 'react';

import { Card, TagContainer } from './styles';

import ManageUserModal from '../Modals/ManageUser';
import { Header, HeaderContainer } from '../Styled';

import { getUsers, deleteUser, createUser, updateUser } from '@/services/users';
import { useAppSelector } from '@/store/hooks';
import { UserTableType, UserFormType, UserManageType } from '@/types/auth';

const { Content } = Layout;
const { confirm } = Modal;

const options = [
  {
    value: 'samples',
    label: 'Analiza próbek'
  },
  {
    value: 'reports',
    label: 'Raporty'
  },
  {
    value: 'systems',
    label: 'Status systemu'
  },
  {
    value: 'todays',
    label: 'Dane bieżące'
  },
  {
    value: 'users',
    label: 'Użytkownicy'
  },
  {
    value: 'thresholds',
    label: 'Progi akceptacji wad'
  },
  {
    value: 'lots',
    label: 'Etykiety'
  }
];

const Users = (): JSX.Element => {
  const { user } = useAppSelector(({ userState }) => userState);

  const [data, setData] = useState<UserTableType[]>([]);
  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [form] = Form.useForm<UserFormType>();

  const [width, height] = useWindowSize();

  const columns = useMemo<ColumnsType<UserTableType>>(() => [
    {
      title: 'Imię i Nazwisko',
      dataIndex: 'name',
      key: 'name',
      fixed: width > 768 && 'left',
      width: 200,
      render: (_, { first_name, last_name }) => (
        `${first_name} ${last_name}`
      )
    },
    {
      title: 'Adres email',
      dataIndex: 'email',
      key: 'email',
      width: 280
    },
    {
      title: 'Uprawnienia',
      dataIndex: 'permissions',
      key: 'permissions',
      render: (_, { permissions }) => (
        <TagContainer>
          { Object.entries(permissions)
            .map(([key, element]) => element === 1 && (
              <Tag key={key}>
                { options.find(({ value }) => key.includes(value))?.label }
              </Tag>
            )) }
        </TagContainer>
      )
    },
    {
      title: 'Akcje',
      key: 'actions',
      fixed: 'right',
      width: width > 576 ? 200 : 100,
      render: (_, record) => (
        <Space size="small">
          <Button
            icon={<EditOutlined />}
            onClick={(): void => handleEdit(record)}
            size={width > 576 ? 'small' : 'middle'}
            type="link"
          >
            { width > 576 && 'Edytuj' }
          </Button>
          <Tooltip
            open={(record.key === user.id) ? undefined : false}
            overlayStyle={{ width: 200 }}
            placement="topLeft"
            title="Nie można usunąć zalogowanego użytkownika"
          >
            <Button
              danger
              disabled={record.key === user.id}
              icon={<DeleteOutlined />}
              onClick={(): void => removeUserModal(record.key)}
              size={width > 576 ? 'small' : 'middle'}
              type="link"
            >
              { width > 576 && 'Usuń' }
            </Button>
          </Tooltip>
        </Space>
      )
    }
  ], []);

  useEffect(() => {
    const fillTable = async (): Promise<void> => {
      try {
        setLoading(true);
        setData(await getUsers());
      } catch (error) {
        console.error((error as Error).message);
      } finally {
        setLoading(false);
      }
    };
    fillTable();
  }, []);

  const handleEdit = (selectedUser: UserTableType): void => {
    form.setFieldsValue({
      ...selectedUser,
      permissions: Object.entries(selectedUser.permissions).map(([key, value]) => value === 1 && key)
        .filter((value) => value)
    });
    setIsEdit(true);
    setIsModalOpen(true);
  };

  const handleRemove = async (id: string): Promise<void> => {
    try {
      setLoading(true);
      await deleteUser(id);
      setData(await getUsers());
    } catch (error) {
      console.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      setLoading(true);
      if (isEdit) {
        await updateUser(Object.entries(form.getFieldsValue(true)).reduce((prev, [key, value]) => ({
          ...prev,
          [key]: key === 'permissions'
            ? (value as Array<string>).reduce((acc, curr) => ({ ...acc, [curr]: 1 }), {})
            : value
        }), {}) as UserManageType);
      } else {
        await createUser(Object.entries(form.getFieldsValue(true)).reduce((prev, [key, value]) => ({
          ...prev,
          [key]: key === 'permissions'
            ? (value as Array<string>).reduce((acc, curr) => ({ ...acc, [curr]: 1 }), {})
            : value
        }), {}) as UserManageType);
      }
      setData(await getUsers());
    } catch (error) {
      console.error((error as Error).message);
    } finally {
      setIsModalOpen(false);
      setLoading(false);
      setIsEdit(false);
      form.resetFields();
    }
  };

  const handleCancel = (): void => {
    setIsModalOpen(false);
    setIsEdit(false);
    form.resetFields();
  };

  const removeUserModal = (id: string): void => {
    confirm({
      title: 'Usuń użytkownika',
      icon: <ExclamationCircleOutlined />,
      content: 'Czy na pewno chcesz usunąć użytkownika?',
      okText: 'Usuń',
      okType: 'danger',
      onOk() {
        handleRemove(id);
      }
    });
  };

  return (
    <Content>
      <HeaderContainer>
        <Header>
          Użytkownicy
        </Header>
        <Button
          onClick={(): void => setIsModalOpen(true)}
          type="primary"
        >
          Dodaj użytkownika
        </Button>
      </HeaderContainer>
      <Card>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={false}
          scroll={{ x: 1100, y: height - 302 }}
        />
      </Card>
      <ManageUserModal
        form={form}
        isEdit={isEdit}
        isLoading={loading}
        onCancel={handleCancel}
        onFinish={handleSubmit}
        open={isModalOpen}
        permissions={options}
      />
    </Content>
  );
};

export default Users;
