import { Form, Modal } from 'antd';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  @media only screen and (max-width: 576px) {
    padding: 0 16px;
  }
`;

export const Header = styled.h1`
  font-weight: 400;
  color: #222222;
  font-size: 20px;
  line-height: 32px;
  margin: 0;
`;

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 40px 60px 32px 60px;
  }

  @media only screen and (max-width: 576px) {
    .ant-modal-body {
      padding: 24px 32px 16px 32px;
    }
  }
`;

export const StyledFormItem = styled(Form.Item)`
  font-weight: 500;

  .ant-form-item-label {
    padding-bottom: 4px;
  }
`;

export const TabContainer = styled.div`
  background-color: #FFFFFF;
  border: 1px solid #F0F0F0;
  border-top: 0;
  border-radius: 0 0 2px 2px;
`;

export const SelectorContainer = styled.div`
  padding: 24px;
  border-bottom: 1px solid #F0F0F0;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

export const Label = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 12px;
`;

export const Container = styled.div`
  padding: 16px;
`;

export const ChartOptions = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 40px;
  margin-left: auto;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-left: inherit;
    gap: 16px;
  }
`;

export const ChartTypeContainer = styled.div`
  display: flex;
  gap: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const Link = styled.div`
  cursor: pointer;
  color: #1890FF;

  &:hover {
    color: #40A9FF;
  }
`;
