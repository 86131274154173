import styled from 'styled-components';

interface Props {
  color: string;
}

export const Container = styled.div<Props>`
  background-color: #FFFFFF;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 12px 16px 10px 20px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 6px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: ${({ color }): string => (color || '#FFFFFF')};
  }
`;

export const Title = styled.div`
  font-size: 14px;
`;

export const Body = styled.div`
  font-size: 16px;
`;

export const Highlight = styled.span`
  font-weight: 500;
`;
