import styled from 'styled-components';

interface Props {
  somethingSelected?: boolean;
}

export const TabContainer = styled.div<Props>`
  background-color: #FFFFFF;
  border: 1px solid #F0F0F0;
  border-top: 0;
  border-radius: 0 0 2px 2px;

  ${({ somethingSelected }): string => (somethingSelected
    ? 'margin-bottom: 110px;'
    : '')
}
`;

export const RadioContainer = styled.div`
  padding: 24px;
  border-bottom: 1px solid #F0F0F0;
`;

export const Label = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 12px;
`;

export const EmptyLabel = styled.div`
  grid-column: 1/-1;
  text-align: center;
  padding: 20px 0;
`;

export const Container = styled.div`
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  @media (max-width: 1400px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
