import dayjs from 'dayjs';
import queryString from 'query-string';

import axios from '../http-common';

import { APICurrentImagesType, CurrentImagesTabType } from '@/types/current-data';
import { APISamplesType, SamplesTableType, FieldValueType, ChartType, SamplesCategoriesType, APISampleImageType, SampleImageCardType, LineData, ChartSummary } from '@/types/sample';

const fixedValues = [
  'grm_total',
  'weight_sum',
  'weight_avg',
  'gaping_percentage',
  'gaping_weight',
  'melanin_percentage',
  'melanin_weight',
  'hemat_percentage',
  'hemat_weight',
  'overgrowth_percentage',
  'overgrowth_weight',
  'pigment_avg',
  'pigment_outlr_percentage',
  'perr',
  'gate_l'
];

export const getSamples = async (
  startDate: number,
  endDate: number,
  fieldValues: SamplesCategoriesType
): Promise<SamplesTableType[]> => {
  const customQuery = queryString.stringify({
    ts1: startDate,
    ts2: endDate,
    ...fieldValues
  }, { sort: false, arrayFormat: 'separator' });

  try {
    const { data } = await axios.get<APISamplesType[]>(Object.keys(fieldValues).length
      ? `/samples?${customQuery}`
      : `/samples?ts1=${startDate}&ts2=${endDate}&grm,lot,batch,numpalet,station,gname,extnumord`);

    return data.map(({ timestamp, ...others }) => ({
      ...Object.entries(others).reduce((prev, [key, value]) => ({
        ...prev,
        ...(fixedValues.includes(key)
          ? {
            [key]: value !== null
              ? Number(value).toFixed(2)
                .replace('.', ',')
              : '-'
          }
          : { [key]: value })
      }), {
        key: timestamp,
        date: dayjs(timestamp).format('DD.MM.YYYY HH:mm')
      })
    })) as SamplesTableType[];
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const getFieldValue = async (
  name: string,
  startDate: number,
  endDate: number,
  fieldValues: SamplesCategoriesType
): Promise<FieldValueType[]> => {
  const customQuery = queryString.stringify({
    ts1: startDate,
    ts2: endDate,
    ...fieldValues,
    [name]: undefined
  }, { sort: false, arrayFormat: 'separator' });

  try {
    const { data } = await axios.get<(string | number | null)[]>(`/samples/fields/${name}?${customQuery}`);

    return data.map((element) => ({
      value: String(element)
    })).filter(({ value }) => value && value !== '-' && value !== '-1' && value !== 'null');
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const getSampleImages = async (
  defectType: string,
  startDate: number,
  endDate: number,
  fieldValues: SamplesCategoriesType
): Promise<SampleImageCardType[]> => {
  const customQuery = queryString.stringify({
    ts1: startDate,
    ts2: endDate,
    ...fieldValues
  }, { sort: false, arrayFormat: 'separator' });

  try {
    const { data } = await axios.get<APISampleImageType[]>(`/samples/images/${defectType}?${customQuery}`);

    return data.map(({ ts, bucket_image }) => ({
      id: ts,
      src: bucket_image,
      state: false
    }));
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const getAllSampleImages = async (
  startDate: number,
  endDate: number,
  token?: number | null,
  noImages?: boolean,
  limit?: number
): Promise<CurrentImagesTabType> => {
  const customQuery = queryString.stringify({
    ts1: startDate,
    ts2: endDate,
    token,
    limit,
    no_images: noImages
  }, { sort: false, arrayFormat: 'separator' });

  try {
    const { data } = await axios.get<APICurrentImagesType>(`/samples/images/all?${customQuery}`);
    return {
      ...data,
      images: data.images
        ? data.images.map(({ ts, bucket_image }) => ({
          id: ts,
          src: bucket_image
        }))
        : [],
      totalCount: data.totalCount ? data.totalCount : 0
    };
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const getSampleImagesTotalCount = async (startDate: number, endDate: number): Promise<CurrentImagesTabType['totalCount']> => {
  try {
    const { data } = await axios.get<Pick<CurrentImagesTabType, 'totalCount'>>(`/samples/images/total-count?ts1=${startDate}&ts2=${endDate}`);
    return data.totalCount;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const getSamplesChartData = async (
  startDate: number,
  endDate: number,
  selectedChart: string,
  singleFieldValue?: string[]
): Promise<ChartType> => {
  const customQuery = queryString.stringify({
    ts1: startDate,
    ts2: endDate,
    value: singleFieldValue
  }, { sort: false, arrayFormat: 'separator' });

  try {
    const { data } = await axios.get<ChartType>(`/samples/charts/${selectedChart}?${customQuery}`);
    return data;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const getSamplesTimelineData = async (
  startDate: number,
  endDate: number,
  value: string
): Promise<ChartType> => {
  const customQuery = queryString.stringify({
    ts1: startDate,
    ts2: endDate,
    value
  }, { sort: false, arrayFormat: 'separator' });

  try {
    const { data } = await axios.get<ChartType>(`/samples/times?${customQuery}`);
    return data;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const getSamplesSummaryData = async (
  startDate: number,
  endDate: number,
  interval: number
): Promise<LineData[]> => {
  const customQuery = queryString.stringify({
    ts1: startDate,
    ts2: endDate,
    interval
  }, { sort: false, arrayFormat: 'separator' });

  const summary: LineData[] = [
    {
      id: 'gaping',
      data: []
    },
    {
      id: 'overgrowth',
      data: []
    },
    {
      id: 'melanin',
      data: []
    },
    {
      id: 'hemat',
      data: []
    }
  ];

  try {
    const { data } = await axios.get<ChartSummary[]>(`/samples/summary?${customQuery}`);

    data.forEach((element) => {
      summary.forEach((value, index) => {
        summary[index].data.push({ x: dayjs(element.ts).format('YYYY-MM-DD HH:mm'), y: element[value.id as keyof ChartSummary] });
      });
    });

    return summary;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};
