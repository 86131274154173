import { ResponsiveLine } from '@nivo/line';
import { useWindowWidth } from '@react-hook/window-size';
import dayjs from 'dayjs';

import CustomTooltip, { legend } from '../CustomTooltip';

import { LineData } from '@/types/sample';

interface Props {
  chartData: LineData[];
  selectedTime?: string;
}

const legendValues = [
  {
    id: 'gaping',
    label: 'Gaping',
    color: '#fdb562',
    hidden: false
  },
  {
    id: 'overgrowth',
    label: 'Przerost',
    color: '#ffd92f',
    hidden: false
  },
  {
    id: 'melanin',
    label: 'Melanina',
    color: '#80b0d3',
    hidden: false
  },
  {
    id: 'hemat',
    label: 'Krwiak',
    color: '#fb8072',
    hidden: false
  }
];

const Line = ({ chartData, selectedTime }: Props): JSX.Element => {
  const width = useWindowWidth();

  return (
    <ResponsiveLine
      axisBottom={{
        tickSize: 0,
        tickPadding: 10,
        legendPosition: 'middle',
        format: '%H:%M',
        tickValues: selectedTime === '2h' ? 'every 10 minutes' : 'every 1 hour'
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 10,
        legend: 'Natężenie',
        legendPosition: 'middle',
        legendOffset: -55
      }}
      colors={({ id }): string => legend(String(id), legendValues).color}
      curve="monotoneX"
      data={chartData}
      legends={[
        {
          onClick: (element): void => {
            legendValues.find(({ id, hidden }, index) => {
              if (id === element.id) {
                legendValues[index].hidden = !hidden;
              }
              return null;
            });
          },
          data: legendValues,
          anchor: width > 576 ? 'bottom-right' : 'bottom-left',
          direction: 'column',
          justify: false,
          translateX: width > 576 ? 110 : 0,
          translateY: width > 576 ? 0 : 130,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
          toggleSerie: true,
          effects: [
            {
              on: 'hover',
              style: {
                itemBackground: 'rgba(0, 0, 0, .03)',
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
      margin={{ top: 30, right: width > 576 ? 110 : 50, bottom: width > 576 ? 50 : 150, left: 70 }}
      pointBorderColor="#fff"
      pointBorderWidth={2}
      pointSize={8}
      tooltip={({ point }): JSX.Element => CustomTooltip(
        String(point.serieId),
        Number(point.data.yFormatted).toFixed(2),
        dayjs(point.data.x).format('DD.MM.YYYY HH:mm'),
        legendValues
      )}
      useMesh
      xFormat="time:%Y-%m-%d %H:%M"
      xScale={{
        type: 'time',
        format: '%Y-%m-%d %H:%M',
        useUTC: false,
        precision: 'minute'
      }}
    />
  );
};

export default Line;
