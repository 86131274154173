import { useWindowHeight } from '@react-hook/window-size';
import { Empty, Layout, Spin } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';

import { date, previousDate } from './Dates';
import { Summary } from './Tabs';
import { Card, StyledTabs } from './styles';

import { AllImages, Charts, SummaryTable, Timeline } from '../SampleAnalysis/Tabs';
import { Header, HeaderContainer } from '../Styled';

import CONFIG from '@/config';
import { getCurrentData } from '@/services/current-data';
import { setDate, setLoading, setSamplesData } from '@/store/features/samplesState';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

const { Content } = Layout;

const CurrentData = (): JSX.Element => {
  const { samplesData, isLoading } = useAppSelector(({ samplesState }) => samplesState);
  const dispatch = useAppDispatch();

  const height = useWindowHeight();

  const items = useMemo(() => [
    {
      label: 'Podsumowanie',
      key: 'summary',
      children: <Summary
        chartHeight={height - 458}
      />
    },
    {
      label: 'Tabela',
      key: 'table',
      children: <SummaryTable
        currentData
        tableHeight={height - 372}
      />
    },
    {
      label: 'Zdjęcia',
      key: 'images',
      children: <AllImages currentData />
    },
    {
      label: 'Wykresy',
      key: 'charts',
      children: <Charts
        chartHeight={height - 312}
        currentData
      />
    },
    {
      label: 'Zmiany w czasie',
      key: 'timeline',
      children: <Timeline
        chartHeight={height - 312}
        currentData
      />
    }
  ], []);

  useEffect(() => {
    if (dayjs().isBefore(dayjs().startOf('day')
      .add(4, 'hour'))) {
      dispatch(setDate({ ...previousDate }));
    } else {
      dispatch(setDate({ ...date }));
    }
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, CONFIG.refreshTime);
    return () => clearInterval(interval);
  }, []);

  const fetchData = async (): Promise<void> => {
    try {
      dispatch(setLoading(true));
      dispatch(setSamplesData(await getCurrentData(date.start, date.end)));
    } catch (error) {
      console.error((error as Error).message);
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Content>
      <HeaderContainer>
        <Header>
          Dane bieżące
        </Header>
      </HeaderContainer>
      { samplesData.length
        ? (
          <StyledTabs
            items={items}
            type="card"
          />
        )
        : (
          <Spin spinning={isLoading}>
            <Card>
              <Empty
                description="Brak danych z dnia bieżącego"
              />
            </Card>
          </Spin>
        ) }
    </Content>
  );
};

export default CurrentData;
