import { Button } from 'antd';

import { StyledBadge } from './styles';

interface Props {
  count: number;
  children: string;
  openDefectModal: ()=> void;
}

const DefectCard = ({ count, children, openDefectModal }: Props): JSX.Element => (
  <StyledBadge count={count}>
    <Button onClick={openDefectModal}>
      { children }
    </Button>
  </StyledBadge>
);

export default DefectCard;
