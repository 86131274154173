import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 24px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ImageCard = styled.div`
  background-color: #FFFFFF;
  border: 1px solid #F0F0F0;
  width: 50%;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const StatusRow = styled.div`
  padding: 24px;
  border-bottom: 1px solid #F0F0F0;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
  }
`;

export const StatusDate = styled.div`
  text-transform: capitalize;
  font-size: 16px;
  line-height: 20px;
`;

export const StatusGroup = styled.div`
  display: flex;
  align-items: flex-start;

  @media only screen and (max-width: 576px) {
    margin-top: 12px;
  }
`;

export const StatusTitle = styled(StatusDate)`
  margin-right: 4px;
`;

export const TableCard = styled.div`
  background-color: #FFFFFF;
  padding: 16px;
  border: 1px solid #F0F0F0;
  width: 50%;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ImageRow = styled.div`
  padding: 24px;
  padding-bottom: 0;
  height: calc(100% - 96px);
  overflow-y: auto;

  @media only screen and (max-width: 768px) {
    height: auto;
    padding: 24px;
  }
`;

export const SystemImage = styled.img`
  width: 100%;
`;

export const ImagePlaceholder = styled.div`
  text-align: center;
  margin-top: 24px;
`;
