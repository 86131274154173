import { DownOutlined } from '@ant-design/icons';
import { useWindowWidth } from '@react-hook/window-size';
import { Menu, Space, Button } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { StyledHeader, LogoContainer, Logo, StyledMenuIcon, StyledCloseIcon, Dimmer, MenuContainer, StyledMenu, UserContainer, StyledDropdown } from './styles';

import { logo } from '@/assets';
import { logout } from '@/services/auth';
import { setDefaultValues } from '@/store/features/user';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

const NavMenu = (): JSX.Element => {
  const { user } = useAppSelector(({ userState }) => userState);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const [current, setCurrent] = useState(location.pathname.substring(1));
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const width = useWindowWidth();

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      dispatch(setDefaultValues());
      navigate('/login');
    } catch (error) {
      console.error((error as Error).message);
    }
  };

  const items = useMemo(() => [
    user.permissions.lots ? { label: 'Etykiety', key: 'lots' } : null,
    user.permissions.samples ? { label: 'Analiza próbek', key: 'analysis' } : null,
    user.permissions.todays ? { label: 'Dane bieżące', key: 'current' } : null,
    user.permissions.reports ? { label: 'Raporty', key: 'reports' } : null,
    user.permissions.systems ? { label: 'Status systemu', key: 'status' } : null,
    user.permissions.users ? { label: 'Użytkownicy', key: 'users' } : null,
    user.permissions.thresholds ? { label: 'Progi akceptacji wad', key: 'thresholds' } : null,
    width <= 576 ? { label: 'Wyloguj', key: 'logout', onClick: handleLogout } : null
  ], [user]);

  useEffect(() => {
    setCurrent(location.pathname.substring(1));
  }, [location.pathname]);

  const menu = (
    <Menu
      items={[
        {
          label: 'Wyloguj',
          key: '1',
          onClick: handleLogout
        }
      ]}
    />
  );

  return (
    <StyledHeader>
      <LogoContainer>
        <Logo
          onClick={(): void => navigate('/')}
          src={logo}
        />
      </LogoContainer>
      <Dimmer
        isOpen={isMenuOpen}
        onClick={width <= 576 ? (): void => setIsMenuOpen(false) : undefined}
      >
        <MenuContainer
          isOpen={isMenuOpen}
          onClick={(e): void => e.stopPropagation()}
        >
          <StyledMenu
            items={items}
            mode={width > 576 ? 'horizontal' : 'inline'}
            onClick={(item): void => { setIsMenuOpen(false); navigate(`/${item.key}`); }}
            selectedKeys={[current]}
            theme={width > 576 ? 'light' : 'dark'}
          />
        </MenuContainer>
      </Dimmer>
      { width <= 576 && (
        <Button
          icon={isMenuOpen ? <StyledCloseIcon /> : <StyledMenuIcon />}
          onClick={(): void => setIsMenuOpen((prev) => !prev)}
          type="text"
        />
      ) }
      { width > 576 && (
        <UserContainer>
          <StyledDropdown overlay={menu}>
            <Button type="link">
              <Space>
                { `${user.first_name} ${user.last_name}` }
                <DownOutlined />
              </Space>
            </Button>
          </StyledDropdown>
        </UserContainer>
      ) }
    </StyledHeader>
  );
};

export default NavMenu;
