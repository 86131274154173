import { DownloadOutlined } from '@ant-design/icons';
import { useWindowSize } from '@react-hook/window-size';
import { Button, Layout, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';

import { Card } from './styles';

import { Header, HeaderContainer } from '../Styled';

import CONFIG from '@/config';
import { getReports } from '@/services/reports';
import { ReportTableType } from '@/types/reports';

const { Content } = Layout;

const colors = [
  {
    reportType: 1,
    color: 'geekblue',
    label: 'Dzienny raport'
  },
  {
    reportType: 11,
    color: 'purple',
    label: 'Dzienny raport'
  },
  {
    reportType: 2,
    color: 'green',
    label: 'Tygodniowy raport'
  },
  {
    reportType: 12,
    color: 'cyan',
    label: 'Tygodniowy raport'
  },
  {
    reportType: 3,
    color: 'blue',
    label: 'Raport użytkownika'
  }
];

const Reports = (): JSX.Element => {
  const [data, setData] = useState<ReportTableType[]>([]);
  const [loading, setLoading] = useState(false);

  const [width, height] = useWindowSize();

  const columns = useMemo<ColumnsType<ReportTableType>>(() => [
    {
      title: 'Nazwa raportu',
      dataIndex: 'name',
      key: 'name',
      width: '40%'
    },
    {
      title: 'Typ raportu',
      dataIndex: 'status',
      key: 'status',
      render: (value): JSX.Element | undefined => {
        const element = colors.find(({ reportType }) => reportType === value);
        return element && (
          <Tag color={element.color}>
            { element.label }
          </Tag>
        );
      }
    },
    {
      title: 'Data utworzenia',
      dataIndex: 'created',
      key: 'created',
      render: (value): string => dayjs(value).format('DD.MM.YYYY - HH:mm')
    },
    {
      title: 'Akcje',
      key: 'actions',
      dataIndex: 'action',
      width: width > 576 ? 180 : 70,
      fixed: 'right',
      render: (_, { link }) => (
        link !== null
          ? (
            <Button
              href={link}
              icon={<DownloadOutlined />}
              size={width > 576 ? 'small' : 'middle'}
              target="blank"
              type="link"
            >
              { width > 576 && 'Pobierz' }
            </Button>
          )
          : (
            <Button
              loading
              size={width > 576 ? 'small' : 'middle'}
              type="link"
            >
              { width > 576 && 'Przygotowanie' }
            </Button>
          )
      )
    }
  ], []);

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, CONFIG.refreshTime);
    return () => clearInterval(interval);
  }, []);

  const fetchData = async (): Promise<void> => {
    try {
      setLoading(true);
      setData(await getReports());
    } catch (error) {
      console.error((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Content>
      <HeaderContainer>
        <Header>
          Raporty
        </Header>
      </HeaderContainer>
      <Card>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={{ responsive: true }}
          scroll={{ x: 1000, y: height - 302 }}
        />
      </Card>
    </Content>
  );
};

export default Reports;
