import styled from 'styled-components';

export const Card = styled.div`
  background-color: #FFFFFF;
  padding: 16px;
  border: 1px solid #F0F0F0;
  margin-bottom: 24px;
`;

export const TagContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`;
