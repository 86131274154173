import { useWindowHeight } from '@react-hook/window-size';
import { Button, CheckboxOptionType, Radio } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';

import customMessage from '@/components/Message';
import Bar from '@/components/Nivo/Bar';
import { TabContainer, SelectorContainer, Label, Container, ChartOptions, ChartTypeContainer } from '@/components/Styled';
import CONFIG from '@/config';
import { getCurrentTimelineData } from '@/services/current-data';
import { getSamplesTimelineData } from '@/services/sample';
import { useAppSelector } from '@/store/hooks';
import { ChartType } from '@/types/sample';

interface Props {
  chartHeight?: number;
  currentData?: boolean;
}

const chartValues = [
  { label: 'Sztuki', value: 'items' },
  { label: 'Procenty', value: 'percent' }
];

const chartType = [
  { label: 'Stos', value: 'stacked' },
  { label: 'Grupy', value: 'grouped' }
];

const Timeline = ({ chartHeight, currentData }: Props): JSX.Element => {
  const { selectedFields, date } = useAppSelector(({ samplesState }) => samplesState);

  const [filterEmpty, setFilterEmpty] = useState(!currentData);
  const [data, setData] = useState<ChartType>();

  const [selectedTime, setSelectedTime] = useState('');
  const [selectedChartValues, setSelectedChartValues] = useState('percent');
  const [selectedChartType, setSelectedChartType] = useState('grouped');

  const height = useWindowHeight();

  const filteredData = useMemo(() => (
    data
      ? {
        grouped: data.grouped.filter((element) => element.all_items > 20),
        stacked: data.stacked.filter((element) => element.all_items > 20)
      }
      : {
        grouped: [],
        stacked: []
      }
  ), [data]);

  const options = useMemo(() => [
    ((dayjs(date.end).diff(dayjs(date.start), 'day', true) <= 2)
      ? { label: '10 minut', value: '10min' }
      : {}
    ),
    ((dayjs(date.end).diff(dayjs(date.start), 'day', true) <= 4)
      ? { label: '1 godzina', value: '1h' }
      : {}
    ),
    ((dayjs(date.end).diff(dayjs(date.start), 'day', true) >= 1)
      ? { label: '1 dzień', value: '1d' }
      : {}
    )
  ].filter((({ value }) => value)) as CheckboxOptionType[], [selectedFields]);

  useEffect(() => {
    setSelectedTime(String(options.filter(({ value }) => value === '1h')[0]?.value || options[0].value));
  }, [selectedFields, date]);

  useEffect(() => {
    fetchChartData();
    let interval: NodeJS.Timer;
    if (currentData) {
      interval = setInterval(() => {
        fetchChartData();
      }, CONFIG.refreshTime);
    } else {
      setFilterEmpty(true);
    }
    return () => clearInterval(interval);
  }, [selectedTime, date]);

  const fetchChartData = async (): Promise<void> => {
    setData(undefined);
    if (selectedTime !== '') {
      if (currentData) {
        try {
          const response = await getCurrentTimelineData(date.start, date.end, selectedTime);
          setData(response);
        } catch (error) {
          console.error((error as Error).message);
        }
      } else {
        try {
          const response = await getSamplesTimelineData(date.start, date.end, selectedTime);
          setData(response);
        } catch (error) {
          console.error((error as Error).message);
        }
      }
    }
  };

  const checkEmpty = (): void => {
    if (data?.stacked.length === filteredData.stacked.length) {
      customMessage('Brak dodatkowych słupków do wyświetlenia!', 'warning', 5);
    } else {
      setFilterEmpty((prev) => !prev);
    }
  };

  return (
    <TabContainer>
      <SelectorContainer>
        <div>
          <Label>
            Wybrany czas
          </Label>
          <Radio.Group
            onChange={({ target }): void => setSelectedTime(target.value)}
            options={options}
            value={selectedTime}
          />
        </div>
        <ChartOptions>
          { !currentData && (
            <Button
              onClick={checkEmpty}
              onFocus={(e): React.FocusEvent<Element> => e.target.blur()}
              style={{ width: 200 }}
            >
              { filterEmpty ? 'Wyświetl wszystkie słupki' : 'Ukryj nieistotne słupki' }
            </Button>
          ) }
          <ChartTypeContainer>
            <div>
              <Label>
                Typ wykresu
              </Label>
              <Radio.Group
                onChange={({ target }): void => setSelectedChartType(target.value)}
                options={chartType}
                value={selectedChartType}
              />
            </div>
            <div>
              <Label>
                Wyświetlane jednostki
              </Label>
              <Radio.Group
                onChange={({ target }): void => setSelectedChartValues(target.value)}
                options={chartValues}
                value={selectedChartValues}
              />
            </div>
          </ChartTypeContainer>
        </ChartOptions>
      </SelectorContainer>
      <Container style={{ height: chartHeight || height - 264, minHeight: 560 }}>
        { data && filteredData && (
          <Bar
            chartData={filterEmpty ? filteredData : data}
            chartKey="ts"
            chartType={selectedChartType}
            chartValues={selectedChartValues}
            selectedTime={selectedTime}
          />
        ) }
      </Container>
    </TabContainer>
  );
};

export default Timeline;
