import { configureStore } from '@reduxjs/toolkit';

import samplesStateReducer from './features/samplesState';
import userStateReducer from './features/user';

export const store = configureStore({
  reducer: {
    samplesState: samplesStateReducer,
    userState: userStateReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
