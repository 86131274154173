import { Radio } from 'antd';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import ImageCard from './ImageCard';
import SelectionBar from './SelectionBar';
import { TabContainer, RadioContainer, Label, EmptyLabel, Container } from './styles';

import customMessage from '@/components/Message';
import DefectPreviewModal from '@/components/Modals/DefectPreview';
import ImagePreviewModal from '@/components/Modals/ImagePreview';
import CONFIG from '@/config';
import { getSampleImages } from '@/services/sample';
import { setSelected } from '@/store/features/samplesState';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { SampleImageCardType } from '@/types/sample';

interface SelectedImageType {
  id: number;
  src: string;
  state: boolean;
  defect: string;
}

const options = [
  { label: 'Melanina', value: 'melanin' },
  { label: 'Gaping', value: 'gaping' },
  { label: 'Przerost', value: 'overgrowth' },
  { label: 'Krwiak', value: 'hemat' },
  { label: 'Pigment', value: 'pigment' }
];

const Images = (): JSX.Element => {
  const { date, selectedFields, isSelected } = useAppSelector(({ samplesState }) => samplesState);
  const dispatch = useAppDispatch();

  const [selectedToReport, setSelectedToReport] = useState<SelectedImageType[]>([]);
  const [selectedDefect, setSelectedDefect] = useState(options[0].value);
  const [data, setData] = useState<SampleImageCardType[]>([]);

  const selectedNumber = useMemo(() => data.filter(({ state }) => state === true).length, [data, selectedToReport]);
  const somethingSelected = useMemo(() => selectedNumber !== 0 || selectedToReport.length !== 0, [selectedNumber, selectedToReport]);

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [previewModalImage, setPreviewModalImage] = useState({ id: 0, src: '' });
  const [previewModalSubmitState, setPreviewModalSubmitState] = useState(false);

  const [isDefectModalOpen, setIsDefectModalOpen] = useState(false);
  const [defectModalName, setDefectModalName] = useState({ label: '', value: '' });

  useEffect(() => {
    downloadImages();
  }, [selectedDefect, selectedFields]);

  const downloadImages = async (): Promise<void> => {
    try {
      setData(await getSampleImages(selectedDefect, date.start, date.end, selectedFields));
    } catch (error) {
      console.error((error as Error).message);
    }
  };

  const toggleImage = (value: number): void => {
    const dataSwitch = (isDefectModalOpen ? setSelectedToReport : setData) as Dispatch<SetStateAction<(SelectedImageType | SampleImageCardType)[]>>;

    dataSwitch((prevState) => {
      const newState = [...prevState];
      const element = newState.find(({ id }) => id === value);
      if (element) {
        element.state = !element.state;
      }
      return newState;
    });
  };

  const unselectAllImages = (): void => {
    setData((prevState) => {
      const newState = [...prevState];
      newState.forEach((element) => {
        element.state = false;
      });
      return newState;
    });
  };

  const attachToReport = (): void => {
    if (!selectedNumber) {
      customMessage('Nie wybrano zdjęć do dodania', 'warning', 2);
    } else {
      setSelectedToReport((prevState) => {
        const selected = [
          ...prevState, ...data.filter(({ state }) => state).map(({ id, src, state }) => ({
            id,
            src,
            state,
            defect: selectedDefect
          }))
        ];
        return selected.filter((value, index, self) => self.findIndex((value2) => (value2.id === value.id)) === index);
      });
      customMessage('Zaznaczone zdjęcia zostały dodane do raportu', 'success', 2);
      if (!isSelected) {
        dispatch(setSelected(true));
      }
      unselectAllImages();
    }
  };

  const openPreviewModal = (id: number, src: string, state: boolean): void => {
    setPreviewModalSubmitState(state);
    setPreviewModalImage({ id, src });
    setIsPreviewModalOpen(true);
  };

  const closePreviewModal = (): void => {
    setIsPreviewModalOpen(false);
  };

  const submitPreviewModal = (): void => {
    toggleImage(previewModalImage.id);
    setPreviewModalSubmitState((prev) => !prev);
  };

  const openDefectModal = (label: string, value: string): void => {
    setDefectModalName({ label, value });
    setIsDefectModalOpen(true);
  };

  const closeDefectModal = (): void => {
    setSelectedToReport((prevState) => {
      const newState = [...prevState];
      newState.forEach((element) => {
        element.state = true;
      });
      return newState;
    });
    setIsDefectModalOpen(false);
  };

  const submitDefectModal = (): void => {
    setSelectedToReport((prevState) => [...prevState.filter((element) => element.state)]);
    setIsDefectModalOpen(false);
  };

  const switchImage = (direction: string): void => {
    const tempData = isDefectModalOpen ? selectedToReport.filter((element) => element.defect === defectModalName.value) : data;
    const tempIndex = tempData.findIndex(({ id }) => id === previewModalImage.id);

    if (direction === 'ArrowLeft') {
      if (tempIndex) {
        setPreviewModalSubmitState(tempData[tempIndex - 1].state);
        setPreviewModalImage({ id: tempData[tempIndex - 1].id, src: `${CONFIG.bucketLink}${tempData[tempIndex - 1].src}` });
      } else {
        customMessage('Wyświetlono pierwsze zdjęcie', 'warning', 3);
      }
    }
    if (direction === 'ArrowRight') {
      if (tempIndex !== tempData.length - 1) {
        setPreviewModalSubmitState(tempData[tempIndex + 1].state);
        setPreviewModalImage({ id: tempData[tempIndex + 1].id, src: `${CONFIG.bucketLink}${tempData[tempIndex + 1].src}` });
      } else {
        customMessage('Wyświetlono ostatnie zdjęcie', 'warning', 3);
      }
    }
  };

  return (
    <TabContainer somethingSelected={somethingSelected}>
      <RadioContainer>
        <Label>
          Rodzaj wady
        </Label>
        <Radio.Group
          onChange={({ target }): void => setSelectedDefect(target.value)}
          options={options}
          value={selectedDefect}
        />
      </RadioContainer>
      <Container>
        {
          data.length
            ? data.map(({ id, src, state }) => (
              <ImageCard
                key={id}
                isSelected={state}
                openPreviewModal={(): void => openPreviewModal(id, `${CONFIG.bucketLink}${src}`, state)}
                src={`${CONFIG.bucketLink}${src}`}
                toggleImage={(): void => toggleImage(id)}
              />
            ))
            : (
              <EmptyLabel>
                Brak zdjęć do wyświetlenia
              </EmptyLabel>
            )
        }
      </Container>
      { somethingSelected && (
        <SelectionBar
          attachToReport={attachToReport}
          clearSelectedImages={(): void => setSelectedToReport([])}
          defects={options}
          openDefectModal={openDefectModal}
          selectedImagesCount={selectedNumber}
          selectedToReport={selectedToReport}
          unselectAllImages={unselectAllImages}
        />
      ) }
      <DefectPreviewModal
        defectModalImages={selectedToReport.filter((element) => element.defect === defectModalName.value)}
        onCancel={closeDefectModal}
        onOk={submitDefectModal}
        open={isDefectModalOpen}
        openPreviewModal={openPreviewModal}
        title={defectModalName.label}
        toggleImage={toggleImage}
      />
      <ImagePreviewModal
        onCancel={closePreviewModal}
        onOk={submitPreviewModal}
        open={isPreviewModalOpen}
        previewModalSubmitState={previewModalSubmitState}
        src={previewModalImage.src}
        switchImage={switchImage}
      />
    </TabContainer>
  );
};

export default Images;
