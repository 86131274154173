import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { SamplesTableType, SamplesCategoriesType, Date } from '@/types/sample';

export interface SamplesStateType {
  date: Date;
  samplesData: SamplesTableType[];
  selectedFields: SamplesCategoriesType;
  isLoading: boolean;
  isSelected: boolean;
}

const initialState: SamplesStateType = {
  date: {
    start: 0,
    end: 0
  },
  samplesData: [],
  selectedFields: {},
  isLoading: false,
  isSelected: false
};

export const samplesStateSlice = createSlice({
  name: 'samplesState',
  initialState,
  reducers: {
    setDate: (state, action: PayloadAction<Date>) => ({
      ...state,
      date: action.payload
    }),
    setSamplesData: (state, action: PayloadAction<SamplesTableType[]>) => ({
      ...state,
      samplesData: action.payload
    }),
    setFieldsValues: (state, action: PayloadAction<SamplesCategoriesType>) => ({
      ...state,
      selectedFields: action.payload
    }),
    setLoading: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isLoading: action.payload
    }),
    setSelected: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isSelected: action.payload
    })
  }
});

export const { setDate, setSamplesData, setFieldsValues, setLoading, setSelected } = samplesStateSlice.actions;

export default samplesStateSlice.reducer;
