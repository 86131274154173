import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { UserType } from '@/types/auth';

export interface UserStateType {
  user: UserType;
  menuOptions: Array<string | null>;
}

const initialState: UserStateType = {
  user: {
    id: '',
    email: '',
    first_name: '',
    last_name: '',
    permissions: {
      samples: 0,
      reports: 0,
      systems: 0,
      todays: 0,
      users: 0,
      thresholds: 0,
      lots: 0
    }
  },
  menuOptions: []
};

export const userStateSlice = createSlice({
  name: 'userState',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserType>) => ({
      ...state,
      user: action.payload,
      menuOptions: [
        action.payload.permissions.lots ? 'lots' : null,
        action.payload.permissions.samples ? 'analysis' : null,
        action.payload.permissions.todays ? 'current' : null,
        action.payload.permissions.reports ? 'reports' : null,
        action.payload.permissions.systems ? 'status' : null,
        action.payload.permissions.users ? 'users' : null,
        action.payload.permissions.thresholds ? 'thresholds' : null
      ]
    }),
    setDefaultValues: () => ({
      ...initialState
    })
  }
});

export const { setUser, setDefaultValues } = userStateSlice.actions;

export default userStateSlice.reducer;
