import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Col, InputNumber, Layout, Modal, Row, Slider, Space, Spin, Tooltip } from 'antd';
import { useEffect, useState } from 'react';

import { StyledAlert, Paragraph, Card, Title, StyledIcon, SliderContainer, SliderLabel, SliderGroup } from './styles';

import { Header, HeaderContainer } from '../Styled';

import { getThresholds, updateThresholds } from '@/services/thresholds';
import { ThresholdsDataType, ThresholdsValuesType } from '@/types/thresholds';

const { Content } = Layout;

const values = [
  {
    label: 'Melanina',
    key: 'melanin'
  },
  {
    label: 'Gaping',
    key: 'gaping'
  },
  {
    label: 'Przerost',
    key: 'overgrowth'
  },
  {
    label: 'Krwiak',
    key: 'hemat'
  }
];

const Thresholds = (): JSX.Element => {
  const [isEdited, setIsEdited] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ThresholdsDataType>({
    gaping_threshold: 0,
    overgrowth_threshold: 0,
    melanin_threshold: 0,
    hemat_threshold: 0
  });
  const [tempThresholds, setTempThresholds] = useState<ThresholdsValuesType>({
    gaping: 0,
    overgrowth: 0,
    melanin: 0,
    hemat: 0
  });

  useEffect(() => {
    const fetchData = async (): Promise<void> => {
      try {
        setIsLoading(true);
        const response = await getThresholds();
        setData(response);
        setTempThresholds({
          gaping: response.gaping_threshold,
          overgrowth: response.overgrowth_threshold,
          melanin: response.melanin_threshold,
          hemat: response.hemat_threshold
        });
      } catch (error) {
        console.error((error as Error).message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const onReset = (): void => {
    setIsEdited(false);
    setTempThresholds((prev) => ({
      ...prev,
      gaping: data.gaping_threshold,
      overgrowth: data.overgrowth_threshold,
      melanin: data.melanin_threshold,
      hemat: data.hemat_threshold
    }));
  };

  const onChange = (name: string, newValue: number): void => {
    setTempThresholds((prev) => ({
      ...prev,
      [name]: newValue
    }));
  };

  const confirm = (): void => {
    Modal.confirm({
      title: 'Zmiana progów akceptacji wad',
      icon: <ExclamationCircleOutlined />,
      content: 'Czy na pewno chcesz zmienić progi akceptacji wad?',
      okText: 'Potwierdź',
      cancelText: 'Anuluj',
      async onOk() {
        try {
          setIsLoading(true);
          await updateThresholds(tempThresholds);
          setData((prev) => ({
            ...prev,
            gaping_threshold: tempThresholds.gaping,
            overgrowth_threshold: tempThresholds.overgrowth,
            melanin_threshold: tempThresholds.melanin,
            hemat_threshold: tempThresholds.hemat
          }));
        } catch (error) {
          console.error((error as Error).message);
        } finally {
          setIsLoading(false);
          setIsEdited(false);
        }
      }
    });
  };

  return (
    <Spin spinning={isLoading}>
      <Content style={{ overflowX: 'hidden' }}>
        <HeaderContainer>
          <Header>
            Progi akceptacji wad
          </Header>
          { isEdited
            ? (
              <Space size="middle">
                <Button onClick={onReset}>
                  Anuluj
                </Button>
                <Button
                  htmlType="submit"
                  onClick={confirm}
                  type="primary"
                >
                  Zapisz zmiany
                </Button>
              </Space>
            )
            : (
              <Button
                onClick={(): void => setIsEdited((prev) => !prev)}
                type="primary"
              >
                Edytuj progi
              </Button>
            ) }
        </HeaderContainer>
        <StyledAlert
          description={ (
            <>
              <Paragraph>
                Tylko uprawnieni użytkownicy rozumiejący na czym polega dobór progów powinni modyfikować zaprezentowane wartości.
                Mają one
                <strong> wpływ na działanie całego systemu</strong>
                , nie tylko dla jednego użytkownika.
              </Paragraph>
              <Paragraph>
                Zmiana progów ma natychmiastowy wpływ na dane prezentowane na stronie oraz wszystkie raporty wygenerowane po tej zmianie.
                Raporty w plikach .pdf wygenerowane wcześniej
                <strong> nie zostaną zmienione.</strong>
              </Paragraph>
            </>
          ) }
          message="Uwaga!"
          showIcon
          type="warning"
        />
        <Row gutter={[16, 16]}>
          { values.map(({ label, key }) => (
            <Col
              key={key}
              lg={{ span: 6 }}
              md={{ span: 12 }}
              xs={{ span: 24 }}
            >
              <Card>
                <Title>
                  <div>
                    { label }
                  </div>
                  <div>
                    { data[`${key}_threshold` as keyof ThresholdsDataType] }
                  </div>
                </Title>
                { isEdited && (
                  <SliderContainer>
                    <SliderLabel>
                      <div>
                        Nowa wartość
                      </div>
                      { (tempThresholds[key as keyof ThresholdsValuesType] !== 1 && isEdited) && (
                        <Tooltip
                          placement="top"
                          title="Przywróc wartość domyślną"
                        >
                          <StyledIcon
                            onClick={(): void => setTempThresholds((prev) => ({
                              ...prev,
                              [key]: 1
                            }))}
                          />
                        </Tooltip>
                      ) }
                    </SliderLabel>
                    <SliderGroup>
                      <Slider
                        defaultValue={1}
                        max={2.0}
                        min={0.5}
                        onChange={(value): void => onChange(key, value)}
                        step={0.01}
                        style={{ width: '100%' }}
                        value={tempThresholds[key as keyof ThresholdsValuesType]}
                      />
                      <InputNumber
                        defaultValue={1}
                        max={2.0}
                        min={0.5}
                        onChange={(value): void => onChange(key, Number(value))}
                        step={0.01}
                        value={tempThresholds[key as keyof ThresholdsValuesType]}
                      />
                    </SliderGroup>
                  </SliderContainer>
                ) }
              </Card>
            </Col>
          )) }
        </Row>
      </Content>
    </Spin>
  );
};

export default Thresholds;
