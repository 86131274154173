import { Button } from 'antd';
import styled from 'styled-components';

export const PaginationContainer = styled.div`
  grid-column: 1/-1;
  display: flex;
  justify-content: space-between;
  padding: 24px;
  border-bottom: 1px solid #F0F0F0;
`;

export const PaginationContent = styled.div`
  display: flex;
  gap: 16px;
`;

export const Dates = styled.div`
  display: flex;
  align-items: center;
  line-height: 32px;
  text-transform: capitalize;
`;

export const StyledButton = styled(Button)`
  > div {
    display: none;
  }
`;

export const TabContainer = styled.div`
  background-color: #FFFFFF;
  border: 1px solid #F0F0F0;
  border-top: 0;
  border-radius: 0 0 2px 2px;

  @media (max-width: 992px) {
    ${StyledButton}{
      > span {
        display: none;
      }
      > div {
        display: block;
      }
    }
  }

  @media (max-width: 768px) {
    ${PaginationContainer}{
      flex-direction: column;
      gap: 16px;
    }
  }
`;
