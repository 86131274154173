import { CSVLink } from 'react-csv';
import styled from 'styled-components';

export const TabContainer = styled.div`
  background-color: #FFFFFF;
  padding: 16px;
  border: 1px solid #F0F0F0;
  border-top: 0;
  border-radius: 0 0 2px 2px;

  .summary-table .ant-table-column-title {
    white-space: pre;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0 24px 16px;

  @media (max-width: 576px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    padding-left: 0;
  }
`;

export const Header = styled.h2`
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  margin: 0;
`;

export const StyledCSVLink = styled(CSVLink)`
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  box-shadow: 0 2px 0 rgba(0,0,0,0.015);
  cursor: pointer;
  transition: all .3s cubic-bezier(0.645,0.045,0.355,1);
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0,0,0,0.85);
  border: 1px solid #D9D9D9;
  background: #FFFFFF;

  &:hover {
    color: #40A9FF;
    border-color: #40A9FF;
    background: #FFFFFF;
  }
`;
