import { Layout, Spin } from 'antd';

import Filters from './Filters';
import { AllImages, Charts, DefectImages, Summary, SummaryTable, Timeline } from './Tabs';
import { StyledTabs } from './styles';

import { Header, HeaderContainer } from '../Styled';

import { useAppSelector } from '@/store/hooks';

const { Content } = Layout;

const SampleAnalysis = (): JSX.Element => {
  const { isLoading, samplesData } = useAppSelector(({ samplesState }) => samplesState);

  const items = [
    {
      label: 'Tabela',
      key: 'table',
      children: <SummaryTable />
    },
    {
      label: 'Zdjęcia wad',
      key: 'defect-images',
      children: <DefectImages />
    },
    {
      label: 'Wszystkie zdjęcia',
      key: 'all-images',
      children: <AllImages />
    },
    {
      label: 'Wykresy',
      key: 'charts',
      children: <Charts />
    },
    {
      label: 'Zmiany w czasie',
      key: 'timeline',
      children: <Timeline />
    },
    {
      label: 'Podsumowanie dnia',
      key: 'summary',
      children: <Summary />
    }
  ];

  return (
    <Content>
      <Spin spinning={isLoading}>
        <HeaderContainer>
          <Header>
            Analiza próbek
          </Header>
        </HeaderContainer>
        <Filters />
        { samplesData.length !== 0 && (
          <StyledTabs
            items={items}
            type="card"
          />
        ) }
      </Spin>
    </Content>
  );
};
export default SampleAnalysis;
