import dayjs from 'dayjs';

import axios from '../http-common';

import { APISystemStatusType, SystemStatusTableType, SystemImageType } from '@/types/system-status';

export const getSystemStatus = async (): Promise<SystemStatusTableType[]> => {
  try {
    const { data } = await axios.get<APISystemStatusType[]>('/systems');
    return data.map(({ dt, status }) => ({
      key: dt,
      date: dayjs(dt).format('DD.MM.YYYY HH:mm'),
      status
    }));
  } catch (error) {
    throw new Error((error as Error).message);
  }
};

export const getSystemImage = async (): Promise<SystemImageType> => {
  try {
    const { data } = await axios.get<SystemImageType>('/systems/image');
    return data;
  } catch (error) {
    throw new Error((error as Error).message);
  }
};
